import {
  FormControl,
  FormLabel,
  Input,
  Text,
  VStack
} from "@chakra-ui/react";

function DocumentFieldsView({ documentFields }) {
  return (
    <VStack spacing={4} align="stretch">
      {documentFields.map((field, i) => {
        if (field.group_type === "table" && Array.isArray(field.value)) {
          return (
            <React.Fragment key={`${field.id || "table"}-${i}`}>
              <FormLabel mb={0} display="flex" alignItems="center">
                {field.group || field.key}
              </FormLabel>
              <ExtractedFields data={field.value} />
            </React.Fragment>
          );
        } else {
          return (
            <FormControl key={`${field.id || "id"}-${i}`}>
              <FormLabel mb={0} display="flex" alignItems="center">
                {field.name || field.key}
              </FormLabel>
              <Text mb={2} color={"gray.400"} fontSize={"sm"}>
                {field.description}
              </Text>
              <Input value={field.value || ""} readOnly />
            </FormControl>
          );
        }
      })}
    </VStack>
  );
}

export default DocumentFieldsView;
