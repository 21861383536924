import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import ProgressBar from "components/ProgressBar";
import { useCallback, useRef, useState, useContext } from "react";
import { useDropzone } from "react-dropzone";
import UploadDocument from "./components/UploadDocument";
import ExtractDocument from "./components/ExtractDocument";
import { useHistory } from "react-router-dom";
import { AuthContext } from "context/AuthContext";
import { ArrowBackIcon } from "@chakra-ui/icons";

const steps = [
  { label: "Upload Document", id: 1 },
  { label: "Extract Document", id: 2 },
];
const DocumentSingleExtractPage = prop => {
  const { location } = prop;
  const queryParams = new URLSearchParams(location.search);
  const extractionTemplateId = queryParams.get("template_id") || "";

  const { isLoading, accessToken } = useContext(AuthContext);
  if (isLoading) {
    return <Spinner />;
  }

  const history = useHistory();
  const [step, setStep] = useState(1);

  const [file, setFile] = useState(null);
  const [uploadedDocument, setUploadedDocument] = useState(null);
  const [isBackModalOpen, setIsBackModalOpen] = useState(false);

  const inputRef = useRef();

  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];

      setFile(file);
      if (inputRef.current) {
        const dataTransfer = new DataTransfer();
        dataTransfer.items.add(file);
        inputRef.current.files = dataTransfer.files;
      }
    }
  }, []);

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDrop,
    accept: {
      "image/*": [".jpg", ".jpeg", ".png", ".gif"],
      "application/pdf": [".pdf"],
    },
  });
  const files = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {Math.round(file.size / 1024)} KB
    </li>
  ));

  const handleBackClick = () => {
    setIsBackModalOpen(true);
  };

  return (
    <Box p={4} position='relative'>
      {step !== 1 && (
        <>
          <Box
            display='flex'
            gap={1}
            mb={2}
            alignItems={"center"}
            // color='gray.800'
            color='orange.400'
            cursor={"pointer"}
            onClick={() => {
              handleBackClick();
            }}
          >
            <ArrowBackIcon />
            <Text fontSize={"sm"}>Back</Text>
          </Box>
          <Modal
            isOpen={isBackModalOpen}
            onClose={() => setIsBackModalOpen(false)}
            // isCentered
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Warning: Unsaved Changes</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Text>
                  You have unsaved changes. Are you sure you want to proceed?
                </Text>
              </ModalBody>
              <ModalFooter>
                <Button
                  bg={"orange.400"}
                  color='white'
                  mr={2}
                  _hover={{ bg: "orange.300", color: "#fff" }}
                  _active={{ bg: "orange.300", color: "#fff" }}
                  onClick={() => {
                    setIsBackModalOpen(false);
                    setStep(1);
                  }}
                >
                  Proceed
                </Button>
                <Button
                  variant='ghost'
                  onClick={() => setIsBackModalOpen(false)}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
      )}
      <Text fontSize='xl' mb={8} ml={3} mt={2} fontWeight='bold'>
        Single Extract
      </Text>

      <ProgressBar currentStep={step} steps={steps} />
      {step === 1 && (
        <>
          <UploadDocument
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            inputRef={inputRef}
            setFile={setFile}
            setStep={setStep}
            file={file}
            files={files}
            token={accessToken}
            setDocument={setUploadedDocument}
          />
        </>
      )}
      {step === 2 && (
        <ExtractDocument
          file={file}
          step={step}
          handleBack={handleBackClick}
          token={accessToken}
          uploadedDocument={uploadedDocument}
          reusedTemplateId={extractionTemplateId}
        />
      )}
    </Box>
  );
};

export default DocumentSingleExtractPage;
