import { Box, Text } from "@chakra-ui/react";
import ProgressBar from "components/ProgressBar";
import { AuthContext } from "context/AuthContext";
import { useCallback, useContext, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import UploadBulk from "./components/bulk/UploadBulk";
import BulkExtractDocuments from "./components/bulk/BulkExtractDocuments";
import { DocumentContext } from "context/DocumentContext";

const steps = [
  { label: "Upload Document", id: 1 },
  { label: "Extract Document", id: 2 },
];

const CreateBulkExtractPage = () => {
  const [step, setStep] = useState(1);
  const { accessToken } = useContext(AuthContext);

  const [file, setFile] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [token, setToken] = useState(null);

  const [extractionData, setExtractionData] = useState(null);
  const [industryDomain, setIndustryDomain] = useState(null);
  const inputRef = useRef();

  const {
    extractionId,
    setExtractionId,
    extractionTemplateInfo,
    documentId,
    setDocumentId,
    setExtractionTemplateInfo,
    bulkUploadId,
    setBulkUploadId,
    documentType,
    setDocumentType,
    setDocuments,
  } = useContext(DocumentContext);
  const onDrop = useCallback(
    acceptedFiles => {
      // Filter the accepted files to ensure only allowed formats
      const allowedTypes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "application/pdf",
      ];

      const filteredFiles = acceptedFiles.filter(file =>
        allowedTypes.includes(file.type)
      );

      if (filteredFiles.length > 0) {
        const file = filteredFiles[0]; // Still using the first accepted file

        setFile(file);
        setSelectedFiles(filteredFiles);

        if (inputRef.current) {
          const dataTransfer = new DataTransfer();
          dataTransfer.items.add(file);
          inputRef.current.files = dataTransfer.files;
        }
      }
    },
    [setFile, inputRef]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    acceptedFiles,
  } = useDropzone({
    onDrop,
    accept: {
      "image/*": [".jpg", ".jpeg", ".png", ".gif"],
      "application/pdf": [".pdf"],
    },
    noClick: false, // Allows clicking to open file dialog
    noKeyboard: true, // Disables keyboard shortcuts for file dialog
    noDragEventsBubbling: true, // Prevents drag events from bubbling up
  });
  // Ensure acceptedFiles is a valid array
  const files =
    acceptedFiles && acceptedFiles.length > 0 ? (
      acceptedFiles.length <= 2 ? (
        acceptedFiles.map((file, index) => (
          <li key={file.path}>
            {file.name} - {Math.round(file.size / 1024)} KB
          </li>
        ))
      ) : (
        <>
          <li key={acceptedFiles[0].path}>
            {acceptedFiles[0].name} - {Math.round(acceptedFiles[0].size / 1024)}{" "}
            KB
          </li>
          <li key={acceptedFiles[1].path}>
            {acceptedFiles[1].name} - {Math.round(acceptedFiles[1].size / 1024)}{" "}
            KB
          </li>
          <li style={{ listStyleType: "none" }}>
            {acceptedFiles.length - 2} more files uploaded
          </li>
        </>
      )
    ) : null;
  return (
    <Box p={4}>
      <Text fontSize='xl' mb={8} ml={3} fontWeight='bold'>
        Bulk Extract
      </Text>
      <ProgressBar currentStep={step} steps={steps} />

      {/* Sharing component */}
      {step === 1 && (
        <>
          <UploadBulk
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            inputRef={inputRef}
            setFile={setFile}
            setStep={setStep}
            file={file}
            files={files}
            selectedFiles={selectedFiles}
            token={accessToken}
            setDocumentId={setDocumentId}
            setDocumentType={setDocumentType}
            setIndustryDomain={setIndustryDomain}
            setExtractionId={setExtractionId}
            extractionId={extractionId}
            setExtractionData={setExtractionData}
            bulkUploadId={bulkUploadId}
            setBulkUploadId={setBulkUploadId}
            setDocuments={setDocuments}
          />
        </>
      )}
      {step === 2 && (
        <BulkExtractDocuments
          step={step}
          handleBack={() => {
            setStep(prev => prev - 1);
            history.push("/in/bulk-upload");
          }}
          token={token}
          documentId={documentId}
          documentType={documentType}
          industryDomain={industryDomain}
          extractionId={extractionId}
        />
      )}
    </Box>
  );
};

export default CreateBulkExtractPage;
