import { mode } from "@chakra-ui/theme-tools";

export const globalStyles = {
  colors: {
    orange: {
      500: "#ff5722",
      400: "#ff5722",
      300: "#ff572280",
    },
  },
  styles: {
    global: props => ({
      body: {
        bg: mode("white", "gray.800")(props),
        fontFamily: "'Roboto', sans-serif",
      },
      html: {
        fontFamily: "'Roboto', sans-serif",
      },
    }),
  },
};
