import { useEffect, useRef, useState } from "react";
import {
  Box,
  Flex,
  Button,
  Input,
  FormControl,
  FormLabel,
  VStack,
  Text,
  IconButton,
  Spinner,
  Heading,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton
} from "@chakra-ui/react";
import { Document, Page, pdfjs } from "react-pdf";
import {
  AddIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDownIcon,
  DeleteIcon
} from "@chakra-ui/icons";

import useApiErrorHandler from "hooks/useApiErrorHandler";

import {
  getExtractedTemplate,
  updateExtractionTemplate,
  extractFieldsDocuments,
  saveExtractionTemplate,
} from "api";
import { IoSave } from "react-icons/io5";
import { saveExtractedFields } from "api";
import EditableDocumentFields from "./EditableDocumentFields";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const ExtractDocument = ({
  file,
  step,
  handleBack,
  token,
  uploadedDocument,
  reusedTemplateId,
}) => {
  const { handleApiErrors } = useApiErrorHandler();

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [renderedPageNumber, setRenderedPageNumber] = useState(null);
  const [extractionFields, setExtractionFields] = useState([]);
  const [isExtracting, setIsExtracting] = useState(false);
  const [isSavingTemplate, setIsSavingTemplate] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [templateName, setTemplateName] = useState("");
  const [isSavingExtractionFields, setIsSavingExtractionFields] = useState(
    false
  );
  const [saveButtonText, setSaveButtonText] = useState("Save");

  const [isUpdatingTemplate, setIsUpdatingTemplate] = useState(false);
  const [extractionId, setExtractionId] = useState(null);

  useEffect(() => {
    if (reusedTemplateId) {
      setExtractionId(reusedTemplateId);
    }
  }, []);

  const toast = useToast({
    position: "top-right",
  });
  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => setLoading(false), 500); // Simulate loading time
    return () => clearTimeout(timer);
  }, [pageNumber]);
  const [width, setWidth] = useState(null);
  const [fields, setFields] = useState([
    { fieldName: "", description: "", confidence_score: 0, reason: "" },
  ]);

  const pdfWrapper = useRef(null);
  const addExtractedFieldsRef = useRef(null);

  useEffect(() => {
    const getExtractionDataById = async () => {
      const originalResponse = await getExtractedTemplate(extractionId, token);

      const response = await handleApiErrors(originalResponse);
      const fieldFormat = response.template.fields.map(field => ({
        fieldName: field.field_name,
        description: field.description,
      }));
      setFields(fieldFormat);
      setTemplateName(response.template.extraction_name);
    };
    extractionId && getExtractionDataById();
  }, [extractionId, token]);
  useEffect(() => {
    const handleResize = () => {
      if (pdfWrapper.current) {
        setWidth(pdfWrapper.current.getBoundingClientRect().width);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    window.addEventListener("load", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  function changePage(offset) {
    setRenderedPageNumber(pageNumber);
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    if (pageNumber === 1) return;
    changePage(-1);
  }
  function nextPage() {
    if (pageNumber === numPages) return;
    changePage(1);
  }
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleAddField = () => {
    setFields([...fields, { fieldName: "", description: "" }]);
    setTimeout(() => {
      if (addExtractedFieldsRef.current) {
        addExtractedFieldsRef.current.scrollTop =
          addExtractedFieldsRef.current.scrollHeight;
      }
    }, 0);
  };
  const handleFieldValueChange = (index, event) => {
    const values = [...extractionFields];
    values[index].value = event.target.value;
    setExtractionFields(values);
  };
  const handleFieldChange = (index, event) => {
    const values = [...fields];
    values[index][event.target.name] = event.target.value;
    setFields(values);
  };

  const handleStartExtraction = async () => {
    if (!token) {
      console.error("No token found");
      return;
    }
    setIsExtracting(true);

    try {
      const fieldsToExtract = fields.map(field => ({
        field_name: field.fieldName,
        description: field.description,
      }));
      // Maybe pass description to api to allow llm to extract more accurately.
      // Display description in the extracted fields or not?
      const originalResponse = await extractFieldsDocuments(
        uploadedDocument?.document_id,
        fieldsToExtract,
        token
      );

      const response = await handleApiErrors(originalResponse);

      // now the output is an array of fields
      // field has key, value, confidence_score and reason
      // @Priyanshu, we will discuss how to display score and reason later this week
      const { output } = response;

      const newFields = output.map(
        ({ group, group_type, key, value, confidence_score, reason }) => ({
          group,
          group_type,
          name: key,
          value: value || "",
          ai_generated_value: value || "", // Store the initial value
          confidence_score: confidence_score || 0,
          reason: reason || "",
        })
      );
      setExtractionFields(newFields);
      toast({
        title: "Document extracted",
        description: "The document has been successfully extracted.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      setIsExtracting(false);
    } catch (error) {
      console.error("Error extracting document:", error);

      setIsExtracting(false);
    }
  };

  const handleExportCSV = () => {
    const fieldNames = extractionFields
      .filter(field => field.group_type !== "table") // Exclude fields where group_type equals table
      .map(field => `"${field.name}"`)
      .join(";");
    const fieldValues = extractionFields
      .filter(field => field.group_type !== "table") // Exclude fields where group_type equals table
      .map(field => `"${field.value || ""}"`)
      .join(";");

    const csv = `${fieldNames}\n${fieldValues}`;

    const blob = new Blob([csv], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "extracted_data.csv";
    a.click();
  };

  const handleSaveExtraction = async () => {
    if (extractionId) {
      setIsUpdatingTemplate(true);
      await handleUpdateTemplate();
    } else {
      setTemplateName(`${uploadedDocument?.document_type} Template`);
      setIsModalOpen(true);
    }
  };

  const handleSaveExtractionFields = async () => {
    setSaveButtonText("Saving...");
    setIsSavingExtractionFields(true);
    const fields = extractionFields.map(field => ({
      key: field.name,
      value: field.value,
      ai_generated_value: field.ai_generated_value, // Use the initial value
      ai_confidence_score: String(field.confidence_score),
      ai_reason: field.reason,
    }));
    try {
      const payload = {
        extraction_id: extractionId,
        fields,
      };
      const originalResponse = await saveExtractedFields(
        uploadedDocument?.document_id,
        payload,
        token
      );
      const response = await handleApiErrors(originalResponse);
      if (response.status === 200 || response.status === 201) {
        toast({
          title: "Fields Saved",
          description: "The extracted fields have been saved successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setSaveButtonText("Saved");
      }
    } catch (error) {
      console.error("Error saving fields:", error);
    } finally {
      setIsSavingExtractionFields(false);
    }
  };

  useEffect(() => {
    setSaveButtonText("Save");
  }, [extractionFields]);
  const handleSaveTemplate = async () => {
    try {
      const fieldsToExtract = fields.map(field => ({
        field_name: field.fieldName,
        description: field.description,
      }));
      setIsSavingTemplate(true);
      const originalResponse = await saveExtractionTemplate(
        templateName,
        fieldsToExtract,
        token
      );
      const response = await handleApiErrors(originalResponse);
      setExtractionId(response.data?.extraction_id);
      setTemplateName(response.data?.template_name);
      setIsModalOpen(false);
      toast({
        title: `${templateName} Template Created Successfully`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      setIsSavingTemplate(false);
    } catch (error) {
      console.error("Error extracting document:", error);
      setIsSavingTemplate(false);
    }
  };

  const handleUpdateTemplate = async () => {
    if (!token) {
      console.error("No token found");
      setIsUpdatingTemplate(false);
      return;
    }
    setIsSavingTemplate(true);
    try {
      const fieldsToExtract = fields.map(field => ({
        field_name: field.fieldName,
        description: field.description,
      }));

      const payload = {
        template_name: templateName,
        fields: fieldsToExtract,
      };
      const originalResponse = await updateExtractionTemplate(
        extractionId,
        payload,
        token
      );

      const response = await handleApiErrors(originalResponse);
      setIsModalOpen(false);
      setIsUpdatingTemplate(false);
      toast({
        title: `${templateName} Template Updated Successfully`,
        description: ``,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      setIsSavingTemplate(false);
    } catch (error) {
      console.error("Error updating document:", error);

      setIsSavingTemplate(false);
    }
  };

  return (
    <>
      <Flex
        minHeight='100vh'
        flexDirection={{
          base: "column",
          xl: "row",
        }}
        gap={{
          base: 4,
          xl: 0,
        }}
        padding='4'
        position='relative'
      >
        <Box
          position={"relative"}
          maxWidth={"50%"}
          // width='100%'
          bg='white'
          padding='4'
          boxShadow='md'
          height="1000px"
        >
          {file && file.type === "application/pdf" ? (
            <Box ref={pdfWrapper} w={"100%"} className={"pdfWrapper"}>
              <Document
                file={file}
                onLoadSuccess={onDocumentLoadSuccess}
                className={"pdfDocument"}
                onLoadError={console.error}
              >
                <Page
                  key={pageNumber}
                  pageNumber={pageNumber}
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                  scale={1.7}
                />
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  gap={2}
                  justifyContent={"center"}
                  position={"absolute"}
                  left={"50%"}
                  bottom={6}
                  transform={"translateX(-50%)"}
                >
                  <IconButton
                    onClick={previousPage}
                    aria-label='Previous Page'
                    icon={<ChevronLeftIcon />}
                    disabled={pageNumber <= 1}
                    isDisabled={pageNumber <= 1}
                  />
                  <p>
                    Page {pageNumber} of {numPages}
                  </p>
                  <IconButton
                    onClick={nextPage}
                    isDisabled={pageNumber >= numPages}
                    aria-label='Next Page'
                    icon={<ChevronRightIcon />}
                  />
                </Box>
              </Document>
            </Box>
          ) : (
            file && <img src={URL.createObjectURL(file)} alt='Document' />
          )}
        </Box>

        {/* Right */}
        <Box
          width='50%'
          bg='white'
          padding='6'
          boxShadow='md'
          ml={{
            base: 0,
            xl: 4,
          }}
          height='1000px'
          overflow='auto'
        >
          <>
            <Box p={2}>
              {!uploadedDocument?.document_type &&
                !uploadedDocument?.industry_domain && (
                  <Spinner
                    style={{
                      display: loading ? "block" : "none",
                    }}
                  />
                )}
              <Box display={"flex"} flexDirection={"column"} gap={4}>
                <HStack spacing={4} align='end' position={"relative"}>
                  <HStack spacing={4} align='end' width={"80%"}>
                    <FormControl>
                      {templateName && extractionId && (
                        <Text mb={2}>
                          <span>Template Name:</span> {templateName}
                        </Text>
                      )}
                      <FormLabel mb={4} fontSize='xl'>
                        {uploadedDocument?.document_type
                          ? `${uploadedDocument?.document_type} - `
                          : ""}
                        {uploadedDocument?.industry_domain || ""}
                      </FormLabel>
                    </FormControl>
                  </HStack>
                </HStack>
              </Box>
            </Box>
            <Box p={2}>
              <Heading size='md' mb={1}>
                Add Extractions
              </Heading>
              <Text color={"gray.400"} fontSize={"sm"} mb={4}>
                Fill in the fields below to extract new information from this
                document.
              </Text>
              <Box
                display={"flex"}
                maxHeight={280}
                overflow='auto'
                flexDirection={"column"}
                gap={4}
                ref={addExtractedFieldsRef}
              >
                {fields.map((field, index) => (
                  <HStack
                    spacing={4}
                    align='end'
                    key={index}
                    position={"relative"}
                  >
                    <HStack spacing={4} align='end' width={"80%"}>
                      <FormControl id={`field-name-${index}`}>
                        <FormLabel>Field Name</FormLabel>
                        <Input
                          name='fieldName'
                          placeholder='Address'
                          value={field.fieldName}
                          width={"100%"}
                          onChange={e => handleFieldChange(index, e)}
                        />
                      </FormControl>

                      <FormControl id={`description-${index}`}>
                        <FormLabel>Description</FormLabel>
                        <Input
                          name='description'
                          placeholder='Describe the data you want extracted from the document. Be as specific as possible.'
                          value={field.description}
                          resize={"none"}
                          width={"100%"}
                          onChange={e => handleFieldChange(index, e)}
                        />
                      </FormControl>
                    </HStack>
                    <HStack spacing={2} align='end'>
                      {index === fields.length - 1 && (
                        <IconButton
                          aria-label='Add Field'
                          icon={<AddIcon />}
                          variant='outline'
                          colorScheme='gray'
                          rounded={"md"}
                          _hover={{ bg: "gray.100" }}
                          _active={{ bg: "gray.100" }}
                          onClick={handleAddField}
                        />
                      )}
                      <IconButton
                        aria-label='Remove Field'
                        icon={<DeleteIcon />}
                        variant='outline'
                        colorScheme='gray'
                        rounded={"md"}
                        disabled={fields.length === 1}
                        _hover={{ bg: "gray.100" }}
                        _active={{ bg: "gray.100" }}
                        onClick={() => {
                          const values = [...fields];
                          values.splice(index, 1);
                          setFields(values);
                        }}
                      />
                    </HStack>
                  </HStack>
                ))}
              </Box>

              <HStack alignItems={"end"} gap={6}>
                <Button
                  mt={4}
                  bg={"orange.500"}
                  color='white'
                  // disabled={!fieldName || !description} //|| !field.description
                  disabled={
                    fields.some(field => !field.fieldName) || isExtracting
                  }
                  _hover={{ bg: "orange.500" }}
                  _active={{ bg: "orange.500" }}
                  size='md'
                  onClick={handleStartExtraction}
                  // rounded={"md"}
                >
                  {isExtracting ? "Extracting..." : "Start Extraction"}
                </Button>
                <Button
                  variant='outline'
                  borderWidth={2}
                  borderColor='orange.500'
                  color='orange.500'
                  _hover={{ bg: "orange.500", color: "#fff" }}
                  _active={{ bg: "orange.500", color: "#fff" }}
                  size='md'
                  onClick={handleSaveExtraction}
                  // disabled={extractionFields.length === 0}
                >
                  {extractionId
                    ? isUpdatingTemplate
                      ? "Updating..."
                      : "Update Extraction"
                    : "Save Extraction"}
                </Button>
                <Modal
                  isOpen={isModalOpen}
                  onClose={() => setIsModalOpen(false)}
                  // isCentered
                >
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader>Save Document</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                      <FormControl>
                        <FormLabel>Template Name</FormLabel>
                        <Input
                          value={templateName}
                          onChange={e => setTemplateName(e.target.value)}
                          placeholder='Enter Template Name'
                        />
                      </FormControl>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        bg={isSavingTemplate ? "orange.300" : "orange.400"}
                        color='white'
                        mr={2}
                        _hover={{ bg: "orange.300", color: "#fff" }}
                        _active={{ bg: "orange.300", color: "#fff" }}
                        disabled={isSavingTemplate}
                        onClick={handleSaveTemplate}
                      >
                        {isSavingTemplate ? "Saving..." : "Save"}
                      </Button>
                      <Button
                        variant='ghost'
                        onClick={() => setIsModalOpen(false)}
                      >
                        Cancel
                      </Button>
                    </ModalFooter>
                  </ModalContent>
                </Modal>
              </HStack>

              <Text fontSize={"xs"} color={"gray.400"} mt={2}>
                *AI can make mistakes. Check important info.
              </Text>
            </Box>

            <Box mt={6} p={2}>
              <HStack
                justifyContent={"space-between"}
                mb={4}
                alignItems={"center"}
              >
                <Heading size='md'>Extracted Fields</Heading>
                <Menu>
                  <MenuButton
                    as={Button}
                    color={"orange.400"}
                    p={"6px 20px"}
                    _hover={{ bg: "orange.300", color: "#fff" }}
                    _active={{ bg: "orange.300", color: "#fff" }}
                    borderWidth={2}
                    borderColor={"orange.500"}
                    rightIcon={<ChevronDownIcon />}
                    disabled={extractionFields.length === 0}
                    variant='outline'
                    title="Export does not work for tables, use tables download button instead"
                  >
                    Export as
                  </MenuButton>
                  <MenuList>
                    <MenuItem onClick={handleExportCSV}>
                      CSV
                    </MenuItem>
                  </MenuList>
                </Menu>
              </HStack>
              <VStack spacing={4} align='stretch' maxWidth={"80%"}>
                <EditableDocumentFields
                  extractionFields={extractionFields}
                  onValueChange={handleFieldValueChange}
                />
                {extractionFields.length === 0 && (
                  <Text color={"gray.400"} fontSize={"sm"}>
                    No fields have been extracted yet.
                  </Text>
                )}
                <Button
                  bg={"orange.500"}
                  color='white'
                  leftIcon={
                    isSavingExtractionFields ? (
                      <Spinner size='sm' />
                    ) : (
                      <IoSave />
                    )
                  }
                  disabled={
                    extractionFields.length === 0 ||
                    isSavingExtractionFields ||
                    saveButtonText === "Saved"
                  }
                  _hover={{ bg: "orange.500" }}
                  _active={{ bg: "orange.500" }}
                  size='md'
                  onClick={handleSaveExtractionFields}
                  width='fit-content'
                >
                  {saveButtonText}
                </Button>
              </VStack>
            </Box>
          </>
        </Box>
      </Flex>
    </>
  );
};

export default ExtractDocument;
