import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Icon,
  useToast,
  Link,
} from "@chakra-ui/react";
import { PersonIcon } from "components/Icons/Icons";
import { supabaseClient } from "App";
import { useHistory } from "react-router-dom";
import { SupportIcon } from "components/Icons/Icons";
import { FaSignOutAlt } from "react-icons/fa";

const UserSidebarStack = ({ isSidebarOpen, user }) => {
  const history = useHistory();
  const toast = useToast({
    position: "top-right",
  });
  const handleLogout = async () => {
    try {
      const { error } = await supabaseClient.auth.signOut();
      if (error) {
        console.error("Log out error", error.message);
        toast({
          title: "Logout Error",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Logged Out",
          description: "You have successfully logged out.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        history.push("/login");
        window.location.reload();
      }
    } catch (err) {
      console.error("Unexpected error during logout", err);
      toast({
        title: "Unexpected Error",
        description: "An unexpected error occurred during logout.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      history.push("/login");
    }
  };

  return (
    <Menu placement='auto'>
      <MenuButton width='100%'>
        <Link
          key='user-stack'
          _hover={{
            textDecoration: "none",
            bg: "gray.50",
            color: "gray.800",
          }}
          border='1px solid #f0f0f0'
          color='gray.600'
          _activeLink={{
            textDecoration: "none",
            bg: "gray.50",
            color: "gray.800",
          }}
          rounded='md'
          p='2'
          width='100%'
          display='flex'
          alignItems='center'
          justifyContent={isSidebarOpen ? "flex-start" : "center"}
        >
          <Icon as={PersonIcon} mr={isSidebarOpen ? "3" : "0"} />
          {isSidebarOpen && (
            <Text fontSize={"sm"} fontWeight='bold'>
              {!!user &&
                `${user.first_name || "Guest"} ${user.last_name || "User"}`}
            </Text>
          )}
        </Link>
        {/* <Image
              src='https://via.placeholder.com/150'
              boxSize='40px'
              borderRadius='full'
              mr='4'
            />
            <Text fontSize={"sm"} fontWeight='bold'>
              {!!user &&
                `${user.first_name || "Guest"} ${user.last_name || "User"}`}
            </Text>
            */}
      </MenuButton>
      <MenuList zIndex={10} position={"relative"}>
        <MenuItem>
          <Flex alignItems='center'>
            <Button
              leftIcon={<SupportIcon color='gray.800' />}
              w='full'
              variant='none'
              fontSize={"sm"}
              gap={0}
              justifyContent='flex-start'
              color='gray.400'
            >
              Support
            </Button>
          </Flex>
        </MenuItem>
        <MenuItem onClick={() => history.push("/in/profile")}>
          <Flex alignItems='center'>
            <Button
              leftIcon={<PersonIcon color='gray.800' />}
              w='full'
              variant='none'
              fontSize={"sm"}
              gap={0}
              justifyContent='flex-start'
              color='gray.400'
            >
              Profile
            </Button>
          </Flex>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <Flex alignItems='center'>
            <Button
              leftIcon={<FaSignOutAlt color='#333' />}
              w='full'
              variant='none'
              fontSize={"sm"}
              justifyContent='flex-start'
              gap={2}
              color='gray.400'
            >
              Logout
            </Button>
          </Flex>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default UserSidebarStack;
