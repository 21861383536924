/*!

=========================================================
* DocExtend Dashboard - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/purity-ui-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/purity-ui-dashboard/blob/master/LICENSE.md)

* Design by Creative Tim & Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import ReactDOM from "react-dom/client";

import App from "App";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider, Spinner } from "@chakra-ui/react";
import { Suspense } from "react";
import { AuthProvider } from "context/AuthContext";
import theme from './theme/theme'; // Import your theme

ReactDOM.createRoot(document.getElementById("root")).render(
  <BrowserRouter>
    <ChakraProvider theme={theme}>
      <Suspense fallback={<Spinner />}>
        <AuthProvider>
          <App />
        </AuthProvider>
      </Suspense>
    </ChakraProvider>
  </BrowserRouter>
);
