import { getExtractedTemplate } from "api";
import { supabaseClient } from "App";
import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  Box,
  Flex,
  Button,
  Input,
  FormControl,
  FormLabel,
  Text,
  IconButton,
  Spinner,
  HStack,
  useToast,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Icon,
} from "@chakra-ui/react";
import { ArrowBackIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { IoSave } from "react-icons/io5";
import { updateExtractionTemplate } from "api";

import useApiErrorHandler from "hooks/useApiErrorHandler";

const ExtractionTemplateDetailPage = () => {
  const history = useHistory();
  const { id } = useParams();
  const { handleApiErrors } = useApiErrorHandler();
  const toast = useToast({
    position: "top-right",
  });
  const [templateData, setTemplateData] = useState(null);
  const [token, setToken] = useState(null);
  const [editFields, setEditFields] = useState({});
  useEffect(() => {
    const getUser = async () => {
      const { data, error } = await supabaseClient.auth.getSession();
      if (!data) {
        console.error("Failed to fetch user:", error);
        return;
      }
      setToken(data?.session?.access_token);
    };
    getUser();
  }, [token]);
  useEffect(() => {
    const fetchData = async () => {
      const originalResponse = await getExtractedTemplate(id, token);
      const response = await handleApiErrors(originalResponse);

      setTemplateData(response.template);
    };
    if (token) fetchData();
  }, [token, id]);

  const handleEditClick = index => {
    setEditFields(prev => ({ ...prev, [index]: !prev[index] }));
  };
  const handleInputChange = (index, field, value) => {
    setTemplateData(prev => {
      const newFields = [...prev.fields];
      newFields[index][field] = value;
      return { ...prev, fields: newFields };
    });
  };

  const handleDeleteClick = async index => {
    const fieldId = templateData.fields[index].id; // field_id
    try {
      // await deleteTemplateField(fieldId, token);
      // setTemplateData(prev => {
      //   const newFields = prev.fields.filter((_, i) => i !== index);
      //   return { ...prev, fields: newFields };
      // });
      toast({
        title: "Field deleted.",
        description: "The field has been successfully deleted.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Failed to delete field:", error);
      toast({
        title: "Error.",
        description: "Failed to delete the field.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleUpdateTemplate = async () => {
    try {
      const payload = {
        template_name: templateData.extraction_name,
        fields: templateData.fields.map(field => ({
          field: field.field_name,
          description: field.description,
        })),
      };
      const originalResponse = await updateExtractionTemplate(
        id,
        payload,
        token
      );
      const response = await handleApiErrors(originalResponse);

      toast({
        title: "Template updated.",
        description: "The template has been successfully updated.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Failed to update template:", error);
    }
  };
  return (
    <>
      <Flex
        // bg='gray.50'
        width='100%'
        // minHeight={"100vh"}
        pt={1}
      >
        <Box
          bg='white'
          width='100%'
          ml={{
            base: 0,
            xl: 4,
          }}
        >
          <Box
            display='flex'
            gap={1}
            ml={2}
            alignItems={"center"}
            color='orange.500'
            cursor={"pointer"}
            onClick={() => {
              if (window.history.length > 1) {
                window.history.back();
              } else {
                history.push("/in/templates");
              }
            }}
          >
            <ArrowBackIcon />
            <Text fontSize={"sm"}>Back</Text>
          </Box>
          <>
            <Box p={2}>
              {!templateData && <Spinner />}
              <Box display={"flex"} flexDirection={"column"} gap={4}>
                <HStack spacing={4} align='end' position={"relative"}>
                  <HStack spacing={4} align='end' width={"80%"}>
                    <FormControl>
                      <FormLabel mb={4} fontSize='xl'>
                        {templateData?.extraction_name}
                      </FormLabel>
                    </FormControl>
                  </HStack>
                </HStack>
              </Box>
            </Box>

            {templateData && (
              <Box p={2}>
                <Table variant='simple' borderWidth={1} borderColor={"#E2E8F0"}>
                  <Thead>
                    <Tr>
                      <Th>Field Name</Th>
                      <Th>Description</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {templateData?.fields.map((field, index) => (
                      <Tr key={index}>
                        <Td py={3}>
                          <Text fontSize='md'>
                            {field.field_name || field.field || "N/A"}
                          </Text>
                        </Td>
                        <Td py={3}>
                          <Text fontSize='md'>
                            {field.description || "N/A"}
                          </Text>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            )}

            {/* <Box mt={6} p={2}>
              <HStack
                justifyContent={"space-between"}
                mb={4}
                alignItems={"center"}
              >
                <Heading size='md'>Extracted Fields</Heading>
                <Menu>
                  <MenuButton
                    as={Button}
                    color={"orange.300"}
                    p={"6px 20px"}
                    _hover={{ bg: "orange.300", color: "#fff" }}
                    _active={{ bg: "orange.300", color: "#fff" }}
                    // bg={"orange.500"}
                    borderWidth={2}
                    borderColor={"orange.500"}
                    rightIcon={<ChevronDownIcon />}
                    disabled={extractionFields.length === 0}
                    variant='outline'
                  >
                    Export as
                  </MenuButton>
                  <MenuList>
                    <MenuItem onClick={handleExportCSV}>CSV</MenuItem>
                    <Divider />
                  </MenuList>
                </Menu>
              </HStack>
              <VStack spacing={4} align='stretch' maxWidth={"80%"}>
                {extractionFields.map((field, i) => (
                  <FormControl key={`${field.id || "id"}-${i}`}>
                    <FormLabel mb={0} display='flex' alignItems='center'>
                      {field.name}
                      {field.confidence_score < 0.95 && (
                        <Tooltip label={field.reason}>
                          <Icon as={WarningIcon} color='red.500' ml={2} />
                        </Tooltip>
                      )}
                    </FormLabel>
                    <Text mb={2} color={"gray.400"} fontSize={"sm"}>
                      {field.description}
                    </Text>
                    <Input
                      value={field.value}
                      onChange={e => {
                        e.preventDefault();
                        handleFieldValueChange(i, e);
                      }}
                      borderWidth={field.confidence_score < 0.95 ? 2 : 1}
                      borderColor={field.confidence_score < 0.95 ? "red" : ""}
                    />
                  </FormControl>
                ))}
                {extractionFields.length === 0 && (
                  <Text color={"gray.400"} fontSize={"sm"}>
                    No fields have been extracted yet.
                  </Text>
                )}
                <Button
                  bg={"orange.500"}
                  color='white'
                  disabled={extractionFields.length === 0}
                  _hover={{ bg: "orange.500" }}
                  _active={{ bg: "orange.500" }}
                  size='md'
                  onClick={handleSaveExtractionFields}
                  width='fit-content'
                >
                  Save
                </Button>
              </VStack>
            </Box> */}
          </>
        </Box>
      </Flex>
    </>
  );
};

export default ExtractionTemplateDetailPage;
