import { createClient } from "@supabase/supabase-js";
import "./theme/global.css";
import AppRouter from "AppRouter";
import { useContext } from "react";
import { AuthContext } from "context/AuthContext";
import { Spinner } from "@chakra-ui/react";

export const supabaseClient = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

export default function App() {
  const { isLoading } = useContext(AuthContext);
  if (isLoading) {
    return <Spinner />
  }

  return (
    <AppRouter />
  );
}
