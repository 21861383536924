import { useState } from "react";
import {
  Box,
  Flex,
  Link,
  Icon,
  Text,
  Button,
  Image,
  Divider,
} from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import dashRoutes from "routes";
import UserSidebarStack from "./UserSidebarStack";

const Sidebar = ({ isSidebarOpen, setIsSidebarOpen, userData }) => {
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <Box
      as='nav'
      pos='fixed'
      top='0'
      left='0'
      w={isSidebarOpen ? "275px" : "85px"}
      h='100vh'
      // bg="gray.800"
      bg={"#fefefe"}
      color='white'
      p='5'
      transition='width 0.3s'
      borderRight={"1px solid  #f0f0f0"}
    >
      <Flex direction='column' h='100%' justify='space-between'>
        <Flex
          direction='column'
          align={isSidebarOpen ? "center" : "flex-start"}
        >
          <Flex align='center' justify='space-between' w='100%' mb='12'>
            {isSidebarOpen ? (
              <Image
                src='/logo-doc.png'
                alt='logo'
                // width={10}
                width={150}
                height={12}
                mr={isSidebarOpen ? 2 : 0}
                // style={{ transform: "scale(0.8)" }}
                objectFit='cover'
              />
            ) : (
              <Image
                src='/logo-without-caption.png'
                alt='logo'
                width={12}
                height={10}
                objectFit={"cover"}
              />
            )}
            {/* {isSidebarOpen && (
              <Text color={"#000"} fontWeight={"medium"} fontSize='lg'>
                DocExtend
              </Text>
            )} */}

            <Icon
              onClick={toggleSidebar}
              variant='unstyled'
              m={0}
              p={0}
              bg='transparent'
              _hover={{ bg: "transparent" }}
              _focus={{ boxShadow: "none" }}
              ml={isSidebarOpen ? "auto" : "0"}
              color='gray.500'
              fontSize={20}
              cursor={"pointer"}
              // mr={isSidebarOpen ? "0" : "-14px"}
            >
              <Icon
                as={isSidebarOpen ? ChevronLeftIcon : ChevronRightIcon}
                w={6}
                h={6}
              />
            </Icon>
          </Flex>

          {/* Bulk Extract Button */}
          {isSidebarOpen && (
            <Button
              as={NavLink}
              to='/in/bulk/new'
              colorScheme='orange'
              _hover='orange.300'
              mb='6'
              width='100%'
            >
              Bulk Extract
            </Button>
          )}

          {isSidebarOpen && (
            <Button
              as={NavLink}
              to='/in/single-extract/new'
              variant='outline'
              // colorScheme='gray.600'
              // bg={"gray.500"}
              _hover={{ bg: "orange.400", color: "white" }}
              mb='6'
              width='100%'
              color='gray.600'
            >
              Single Extract
            </Button>
          )}
          <Divider borderColor='gray.200' borderWidth={1} w='100%' my={4} />
          {/* Navigation Links */}
          {dashRoutes.map((route, index) => (
            <Link
              as={NavLink}
              to={route.layout + route.path}
              key={index}
              _hover={{
                textDecoration: "none",
                bg: "gray.50",
                color: "gray.800",
              }}
              border='1px solid #f0f0f0'
              color='gray.600'
              _activeLink={{
                textDecoration: "none",
                bg: "gray.50",
                color: "gray.800",
              }}
              rounded='md'
              p='3'
              mt='2'
              width='100%'
              display='flex'
              alignItems='center'
              justifyContent={isSidebarOpen ? "flex-start" : "center"}
            >
              <Icon as={() => route.icon} mr={isSidebarOpen ? "3" : "0"} />
              {isSidebarOpen && <Text ml={2}>{route.name}</Text>}
            </Link>
          ))}
        </Flex>
        <Flex
          position='absolute'
          left='8px'
          bottom='48px'
          width='100%'
          pl='1'
          pr='3.5'
        >
          <UserSidebarStack isSidebarOpen={isSidebarOpen} user={userData}/>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Sidebar;
