import { getSingleDocumentById, getExtractedTemplate } from "api";
import { AuthContext } from "context/AuthContext";
import { useContext, useEffect, useRef, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import useApiErrorHandler from "hooks/useApiErrorHandler";

import {
  Box,
  Flex,
  Button,
  Input,
  FormControl,
  FormLabel,
  VStack,
  Text,
  Spinner,
  Heading,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Divider,
  useToast,
  Image,
} from "@chakra-ui/react";
import { ChevronDownIcon, ArrowBackIcon } from "@chakra-ui/icons";
import DocumentFieldsView from "./components/DocumentFieldsView";

const DocumentSingleExtractDetailPage = () => {
  const { id } = useParams();
  const { handleApiErrors } = useApiErrorHandler();

  const history = useHistory();
  const toast = useToast({
    position: "top-right",
  });
  const pdfWrapper = useRef(null);
  const addExtractedFieldsRef = useRef(null);
  const { accessToken: token } = useContext(AuthContext);
  const [uploadedDocument, setUploadedDocument] = useState(null);
  const [extractionData, setExtractionData] = useState(null);
  const [csvData, setCsvData] = useState([]);

  const getDocById = async () => {
    try {
      const originalResponse = await getSingleDocumentById(id, token);
      const data = await handleApiErrors(originalResponse);

      setUploadedDocument(data);
      // setExtractionFields(data.fields);
    } catch (error) {
      console.error("Failed to fetch document:", error);
    }
  };
  useEffect(() => {
    id && getDocById();
  }, [id]);

  const fetchExtractionData = async () => {
    const originalResponse = await getExtractedTemplate(
      uploadedDocument?.extraction_id,
      token
    );

    const response = await handleApiErrors(originalResponse);
    setExtractionData(response.template);
  };

  useEffect(() => {
    if (
      uploadedDocument?.extraction_id &&
      uploadedDocument?.extraction_id !== "None"
    ) {
      fetchExtractionData();
    }
    // uploadedDocument?.extraction_id && fetchExtractionData();
  }, [uploadedDocument?.extraction_id]);

  // const handleExportCSV = () => {
  //   const fieldNames = extractionData.fields
  //     .map(field => `"${field.name}"`)
  //     .join(",");
  //   const fieldValues = extractionData.fields
  //     .map(field => `"${field.value || ""}"`)
  //     .join(",");

  //   const csv = `${fieldNames}\n${fieldValues}`;
  //   setCsvData(csv);

  //   const blob = new Blob([csv], { type: "text/csv" });
  //   const url = URL.createObjectURL(blob);
  //   const a = document.createElement("a");
  //   a.href = url;
  //   a.download = "extracted_data.csv";
  //   a.click();
  // };

  return (
    <>
      <Flex
        minHeight='100vh'
        flexDirection={{
          base: "column",
          xl: "row",
        }}
        gap={{
          base: 4,
          xl: 0,
        }}
        padding='4'
        pb={6}
        position='relative'

        // bg='gray.50'
      >
        {
          <Box
            position={"relative"}
            width={"40%"}
            // width='100%'
            bg='white'
            padding='4'
            boxShadow='md'
            mt={8}
            zIndex={1}
          >
            <Box
              display='flex'
              gap={1}
              my={2}
              position={"absolute"}
              top={-14}
              alignItems={"center"}
              color='orange.500'
              cursor={"pointer"}
              onClick={() => {
                if (window.history.length > 1) {
                  window.history.back();
                } else {
                  history.push("/in/single-extract/new");
                }
              }}
            >
              <ArrowBackIcon />
              <Text fontSize={"sm"}>Back</Text>
            </Box>
            <Text
              position={"absolute"}
              top={-6}
              fontSize='lg'
              fontWeight='semibold'
            >
              Single Extraction Details
            </Text>
            {uploadedDocument?.file_name?.split(".").pop() === "pdf" &&
            uploadedDocument?.document_url ? (
              <Box ref={pdfWrapper} w={"100%"} className={"pdfWrapper"}>
                <iframe
                  src={`${uploadedDocument?.document_url}#view=fitH&toolbar=0&navpanes=0`}
                  style={{
                    height: "75%",
                    width: "100%",
                    userSelect: "none",
                  }}
                />{" "}
              </Box>
            ) : (
              uploadedDocument?.document_url && (
                <Image
                  src={uploadedDocument?.document_url}
                  alt={uploadedDocument?.file_name}
                />
              )
            )}
          </Box>
        }

        {/* Right */}
        <Box
          width='50%'
          bg='white'
          padding='6'
          boxShadow='md'
          ml={{
            base: 0,
            xl: 4,
          }}
          mt={8}
        >
          <>
            <Box p={2}>
              {!uploadedDocument?.extraction_id && <Spinner />}
              {extractionData && (
                <Box display={"flex"} flexDirection={"column"} gap={4}>
                  <Text mb={2} fontWeight='500'>
                    {extractionData?.extraction_name}
                  </Text>
                  <HStack spacing={4} align='end' position={"relative"}>
                    <HStack spacing={4} align='end' width={"80%"}>
                      <FormControl>
                        {uploadedDocument?.file_name && (
                          <Text mb={2}>
                            <span
                              style={{
                                fontWeight: "500",
                              }}
                            >
                              File:
                            </span>
                            {uploadedDocument.file_name}
                          </Text>
                        )}
                      </FormControl>
                    </HStack>
                  </HStack>
                </Box>
              )}
            </Box>

            <Box p={2}>
              <Heading size='md' mb={1}>
                Add Extractions
              </Heading>
              <Text color={"gray.400"} fontSize={"sm"} mb={4}>
                Fill in the fields below to extract new information from this
                document.
              </Text>
              <Box
                display={"flex"}
                maxHeight={280}
                overflow='auto'
                flexDirection={"column"}
                gap={4}
                ref={addExtractedFieldsRef}
              >
                {extractionData?.fields?.map((field, index) => (
                  <HStack
                    spacing={4}
                    align='end'
                    key={index}
                    position={"relative"}
                  >
                    <HStack spacing={4} align='end' width={"80%"}>
                      <FormControl id={`field-name-${index}`}>
                        <FormLabel>Field Name</FormLabel>
                        <Input
                          name='fieldName'
                          placeholder='Address'
                          value={field.field_name}
                          width={"100%"}
                        />
                      </FormControl>
                      <FormControl id={`description-${index}`}>
                        <FormLabel>Description</FormLabel>
                        <Input
                          name='description'
                          placeholder='Describe the data you want extracted from the document. Be as specific as possible.'
                          value={field.description}
                          resize={"none"}
                          width={"100%"}
                        />
                      </FormControl>
                    </HStack>
                  </HStack>
                ))}
              </Box>
              <Text fontSize={"xs"} color={"gray.400"} mt={2}>
                *AI can make mistakes. Check important info.
              </Text>
            </Box>
            <Box mt={6} p={2}>
              <HStack
                justifyContent={"space-between"}
                mb={4}
                alignItems={"center"}
              >
                <Heading size='md'>Extracted Fields</Heading>
                <Menu>
                  <MenuButton
                    as={Button}
                    color={"orange.400"}
                    p={"6px 20px"}
                    _hover={{ bg: "orange.300", color: "#fff" }}
                    _active={{ bg: "orange.300", color: "#fff" }}
                    borderWidth={2}
                    borderColor={"orange.500"}
                    rightIcon={<ChevronDownIcon />}
                    variant='outline'
                  >
                    Export as
                  </MenuButton>
                  <MenuList>
                    <MenuItem>CSV</MenuItem>
                    <Divider />
                    <MenuItem>JSON</MenuItem>
                  </MenuList>
                </Menu>
              </HStack>
              <VStack
                maxHeight={340}
                overflow='auto'
                spacing={4}
                align='stretch'
                maxWidth={"100%"}
              >
                {!!uploadedDocument?.fields?.length ? (
                  <DocumentFieldsView documentFields={uploadedDocument?.fields || []} />
                ) : (
                  <Text color={"gray.400"} fontSize={"sm"}>
                    No fields have been extracted yet.
                  </Text>
                )}
              </VStack>
            </Box>
          </>
        </Box>
      </Flex>
    </>
  );
};

export default DocumentSingleExtractDetailPage;
