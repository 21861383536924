import { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Flex,
  Button,
  Input,
  FormControl,
  FormLabel,
  VStack,
  Text,
  IconButton,
  Spinner,
  Heading,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Divider,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Tooltip,
  Icon,
  Table,
  Thead,
  Tr,
  Tbody,
} from "@chakra-ui/react";
import { Document, Page, pdfjs } from "react-pdf";
import {
  AddIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDownIcon,
  DeleteIcon,
  WarningIcon,
} from "@chakra-ui/icons";
import useApiErrorHandler from "hooks/useApiErrorHandler";

import { extractFieldsDocuments, saveExtractionTemplate } from "api";
import { extractDocument, getExtractedTemplate } from "api";
import { IoSave } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import { saveExtractedFields } from "api";
import DocumentsTable from "views/Dashboard/Documents/components/DocumentsTable";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import { FaTrash } from "react-icons/fa";
import CardBody from "components/Card/CardBody";
import BulkDocTable from "./BulkDocTable";
import { DocumentContext } from "context/DocumentContext";
import { bulkExtractDocument } from "api";
import { getBulkExtractionStatus, getBulkExtractionsData } from "api";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const BulkExtractDocuments = ({
  step,
  handleBack,
  token,
  documentId,
  documentType,
  extractionId,
}) => {
  const { handleApiErrors } = useApiErrorHandler();
  const [loading, setLoading] = useState(false);
  const [extractionFields, setExtractionFields] = useState([]);
  const [isExtracting, setIsExtracting] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBackModalOpen, setIsBackModalOpen] = useState(false);
  const [templateName, setTemplateName] = useState("");
  const [isExtractionSaved, setExtractionIsSaved] = useState(false);
  const [isExtractionFieldsSaved, setIsExtractionFieldsSaved] = useState(false);
  const [isSavingExtractionFields, setIsSavingExtractionFields] = useState(
    false
  );
  const [isProcessing, setIsProcessing] = useState(false);
  const [status, setStatus] = useState("");
  // const [tableData, setTableData] = useState([...selectedFiles]);
  const [tableData, setTableData] = useState([]);
  const [isPolling, setIsPolling] = useState(false);

  const history = useHistory();
  const toast = useToast({
    position: "top-right",
  });
  const { bulkUploadId, setDocumentId, setDocuments } = useContext(
    DocumentContext
  );

  const [fields, setFields] = useState([
    { fieldName: "", description: "", confidence_score: 0, reason: "" },
  ]);
  const addExtractedFieldsRef = useRef(null);

  useEffect(() => {
    let intervalId;

    if (isPolling) {
      intervalId = setInterval(async () => {
        if (bulkUploadId) {
          const statusResponse = await getBulkExtractionStatus(
            bulkUploadId,
            token
          );
          const statusData = await handleApiErrors(statusResponse);
        }
      }, 5000);
    }

    return () => clearInterval(intervalId);
  }, [isPolling, bulkUploadId, token]);

  useEffect(() => {
    const getExtractionTemplateData = async () => {
      try {
        const originalResponse = await getExtractedTemplate(
          extractionId,
          token
        );

        const response = await handleApiErrors(originalResponse);
        const fieldFormat = response.template?.fields.map(field => ({
          fieldName: field.field_name,
          description: field.description,
        }));
        setFields(fieldFormat);
        setTemplateName(response.template?.extraction_name);
      } catch (error) {
        console.error("Error fetching extraction data:", error);
      }
    };

    if (extractionId && token) getExtractionTemplateData();
  }, [token, extractionId]);

  useEffect(() => {
    const getExtractionDataById = async () => {
      setLoading(true);
      try {
        const originalResponse = await getBulkExtractionsData(
          bulkUploadId,
          token
        );

        const response = await handleApiErrors(originalResponse);
        setLoading(false);
        setDocuments(response.documents);
      } catch (error) {
        console.error("Error fetching bulk extraction data:", error);
      }
    };

    if (bulkUploadId && token) getExtractionDataById();
  }, [bulkUploadId, token]);

  const handleAddField = () => {
    setFields([...fields, { fieldName: "", description: "" }]);
    setTimeout(() => {
      if (addExtractedFieldsRef.current) {
        addExtractedFieldsRef.current.scrollTop =
          addExtractedFieldsRef.current.scrollHeight;
      }
    }, 0);
  };
  const handleFieldValueChange = (index, event) => {
    const values = [...extractionFields];
    values[index].value = event.target.value;
    setExtractionFields(values);
  };
  const handleFieldChange = (index, event) => {
    const values = [...fields];
    values[index][event.target.name] = event.target.value;
    setFields(values);
  };

  const handleAddExtraction = async () => {
    if (!token) {
      console.error("No token found");
      return;
    }
    setIsExtracting(true);

    try {
      setIsProcessing(true);
      const originalResponse = await bulkExtractDocument(
        extractionId,
        bulkUploadId,
        token
      );
      const response = await handleApiErrors(originalResponse);

      setIsProcessing(false);
      // const { output } = response;

      // const newFields = output.map(
      //   ({ key, value, confidence_score, reason }) => ({
      //     name: key
      //       .replace(/_/g, " ")
      //       .replace(/\b\w/g, char => char.toUpperCase()),
      //     description: "",
      //     value: value || "",
      //     initialValue: value || "",
      //     confidence_score: confidence_score || 0,
      //     reason: reason || "",
      //   })
      // );
      // setExtractionFields(newFields);
      toast({
        title: "Document extracted",
        description: "The document has been successfully extracted.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setIsExtracting(false);

      setIsPolling(true);
    } catch (error) {
      console.error("Error extracting document:", error);

      setIsExtracting(false);
    }
  };

  const handleExportCSV = () => {
    const fieldNames = extractionFields
      .map(field => `"${field.name}"`)
      .join(",");
    const fieldValues = extractionFields
      .map(field => `"${field.value || ""}"`)
      .join(",");

    const csv = `${fieldNames}\n${fieldValues}`;

    const blob = new Blob([csv], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "extracted_data.csv";
    a.click();
  };

  const handleSaveExtraction = async () => {
    // setIsSavingExtraction(true);
    if (extractionId) {
      const data = await handleSaveTemplate();
    } else {
      setTemplateName(`${documentType} Template`);
      setExtractionIsSaved(true);
      setIsModalOpen(true);
    }
  };
  const handleSaveExtractionFields = async () => {
    setIsSavingExtractionFields(true);
    /*{
    "name": "Shipper Reference",
    "description": "",
    "value": "34567",
    "confidence_score": 0.95,
    "reason": "The shipper's reference is explicitly stated in the document under the relevant section."
}*/
    const fieldsToSave = {
      fields: extractionFields.map(field => ({
        key: field.name,
        value: field.value,
        ai_generated_value: field.initialValue, // Use the initial value
        ai_confidence_score: String(field.confidence_score),
        ai_reason: field.reason,
      })),
    };
    try {
      const originalResponse = await saveExtractedFields(
        documentId,
        fieldsToSave,
        token
      );
      const response = await handleApiErrors(originalResponse);

      if (response.status === 200 || response.status === 201) {
        setIsExtractionFieldsSaved(true);
        toast({
          title: "Fields Saved",
          description: "The extracted fields have been saved successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error saving fields:", error);
    } finally {
      setIsSavingExtractionFields(false);
    }
  };

  return (
    <>
      <Flex
        // minHeight='100vh'
        flexDirection={{
          base: "column",
          xl: "row",
        }}
        gap={{
          base: 4,
          xl: 0,
        }}
        padding='4'
      >
        <Box
          // width={ifCreate ? "80%" : "50%"}
          width='100%'
          bg='white'
          padding='6'
          boxShadow='md'
          ml={{
            base: 0,
            xl: 4,
          }}
        >
          <>
            <Box p={2}>
              <Box display={"flex"} flexDirection={"column"} gap={4}>
                <HStack spacing={4} align='end' position={"relative"}>
                  <HStack spacing={4} align='end' width={"100%"}>
                    <FormControl
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      {!templateName && <Spinner />}

                      <Text mb={2} fontSize={"lg"}>
                        {templateName && extractionId && templateName}
                      </Text>
                      <Button
                        bg={"orange.500"}
                        color='white'
                        // disabled={!fieldName || !description} //|| !field.description
                        disabled={fields.some(field => !field.fieldName)}
                        _hover={{ bg: "orange.500" }}
                        _active={{ bg: "orange.500" }}
                        size='md'
                        onClick={handleAddExtraction}
                      >
                        {isProcessing
                          ? "Processing..."
                          : "Start  Batch Processing"}
                      </Button>
                    </FormControl>
                  </HStack>
                </HStack>

                {loading ? (
                  <Spinner />
                ) : (
                  <BulkDocTable
                    title={"Files"}
                    headings={[
                      "",
                      "Name",
                      "Status",
                      "Document Type",
                      "Industry Domain",
                      "Document Link",
                    ]}
                    data={tableData}
                    setTableData={setTableData}
                    token={token}
                  />
                )}

                <Box p={2}>
                  <Text fontSize={"xs"} color={"gray.400"} mt={2}>
                    *AI can make mistakes. Check important info.
                  </Text>
                </Box>
              </Box>
            </Box>
          </>
        </Box>
      </Flex>

      {/* Back to prev step */}
      {step !== 1 && (
        <>
          <Text
            onClick={() => {
              if (
                !(isExtractionSaved && isExtractionFieldsSaved) &&
                (extractionFields.length > 0 || fields.length > 1)
              ) {
                setIsBackModalOpen(true);
              } else {
                handleBack();
              }
            }}
            fontSize={"sm"}
            color='orange.500'
            ml={4}
            cursor={"pointer"}
          >
            Back
          </Text>
          {!(isExtractionSaved && isExtractionFieldsSaved) && (
            <Modal
              isOpen={isBackModalOpen}
              onClose={() => setIsBackModalOpen(false)}
              // isCentered
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Warning: Unsaved Changes</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Text>
                    You have unsaved changes. Are you sure you want to proceed?
                  </Text>
                </ModalBody>
                <ModalFooter>
                  <Button
                    bg={"orange.300"}
                    color='white'
                    mr={2}
                    _hover={{ bg: "orange.300", color: "#fff" }}
                    _active={{ bg: "orange.300", color: "#fff" }}
                    onClick={() => {
                      setIsBackModalOpen(false);
                      handleBack();
                    }}
                  >
                    Proceed
                  </Button>
                  <Button
                    variant='ghost'
                    onClick={() => setIsBackModalOpen(false)}
                  >
                    Cancel
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          )}
        </>
      )}
    </>
  );
};

export default BulkExtractDocuments;
