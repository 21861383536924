import React from "react";
// Chakra imports
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Text,
  useColorModeValue,
  UnorderedList,
  ListItem,
  Center,
  useToast,
  Image,
} from "@chakra-ui/react";
import { useState, useCallback } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";

import { supabaseClient } from "App";
// Assets
const validateEmail = email => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
function SignUp() {
  const titleColor = useColorModeValue("orange.500");
  const textColor = useColorModeValue("gray.400", "white");
  const bgColor = useColorModeValue("white", "gray.700");
  const bgIcons = useColorModeValue("orange.500", "rgba(255, 255, 255, 0.5)");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();

  const toast = useToast({
    position: "top-right",
  });
  const handleSignup = useCallback(async () => {
    try {
      if (name && email && password) {
        if (!validateEmail(email)) {
          throw new Error("Invalid email address");
        }
        const { data, error } = await supabaseClient.auth.signUp({
          name: name,
          email: email,
          password: password,
          options: {
            emailRedirectTo:  process.env.REACT_APP_REDIRECT_TO
          },
        });
        if (data) {
          toast({
            title: "Sign up successful",
            description: "You have successfully signed up.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          history.push("/in/verify-email");
          window.location.reload();
        }
        if (error) {
          console.error(error);
          throw error;
        }
      } else {
        toast({
          title: "Input Error",
          description: "Name, email, and password are required to sign up.",
          status: "warning",
          duration: 5000,
          isClosable: true,
        });
        throw new Error("name, email and password are required to signup");
      }
    } catch (error) {
      toast({
        title: "Something went wrong",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }, [name, email, password]);

  const handleGoogleSignin = React.useCallback(async () => {
    try {
      const { error } = await supabaseClient.auth.signInWithOAuth({
        provider: "google",
      });
      if (error) {
        console.error(error);
        throw error;
      }
    } catch (error) {
      toast({
        title: "Google Sign In Error",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }, []);

  return (
    <Flex position='relative' mb='40px'>
      <Flex
        h={{ sm: "initial", md: "75vh", lg: "85vh" }}
        w='100%'
        maxW='1044px'
        mx='auto'
        justifyContent='space-between'
        mb='30px'
        pt={{ sm: "100px", md: "0px" }}
      >
        <Flex
          alignItems='center'
          justifyContent='start'
          // w={{ base: "100%", md: "50%", lg: "42%" }}
        >
          <Box w='100%' mr={6} display='flex' flexDirection='column' gap='6'>
            <Image
              src='/logo-doc.png'
              alt='logo'
              // width={10}
              width={150}
              height={12}
              objectFit='cover'
            />
            <Heading as='h2' fontSize='2xl' fontWeight='bold'>
              Streamline your document management with intelligent data
              extraction solutions.
            </Heading>
            <Box>
              <Text fontWeight='bold'>
                Automatically capture key information from your documents:
              </Text>
              <UnorderedList spacing={2} ml='6' fontWeight='normal'>
                <ListItem>
                  Extract invoice details quickly—customize, verify, and export
                  with ease.
                </ListItem>
                <ListItem>
                  Save and manage templates for consistent extraction across all
                  documents.
                </ListItem>
                <ListItem>
                  Extract data from multiple documents and export to
                  spreadsheets for analysis.
                </ListItem>
                <ListItem>
                  Ensure accuracy with confidence scores and real-time alerts
                  for validation.
                </ListItem>
              </UnorderedList>
            </Box>
          </Box>
          <Flex
            direction='column'
            w='100%'
            background='transparent'
            p='48px'
            mt={{ md: "150px", lg: "80px" }}
            textAlign={"center"}
          >
            <Text
              color={titleColor}
              fontSize='32px'
              mb='10px'
              fontWeight={"bold"}
            >
              Sign Up
            </Text>
            <Center py={8}>
              <Button
                w={"full"}
                maxW={"md"}
                variant={"outline"}
                leftIcon={<FcGoogle />}
                onClick={handleGoogleSignin}
              >
                <Center>
                  <Text>Sign Up with Google</Text>
                </Center>
              </Button>
            </Center>
            <Text
              mb='36px'
              ms='4px'
              color={textColor}
              fontWeight='bold'
              fontSize='14px'
            >
              or
            </Text>
            <FormControl
              onKeyDown={e => {
                if (e.key === "Enter" || e.keyCode === 13 || e.which === 13) {
                  handleSignup();
                }
              }}
            >
              <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                Name
              </FormLabel>
              <Input
                fontSize='sm'
                ms='4px'
                borderRadius='15px'
                type='text'
                placeholder='Your full name'
                mb='24px'
                size='lg'
                value={name}
                onChange={e => setName(e.target.value)}
              />
              <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                Email
              </FormLabel>
              <Input
                fontSize='sm'
                ms='4px'
                borderRadius='15px'
                type='email'
                placeholder='Your email address'
                mb='24px'
                size='lg'
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
              <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                Password
              </FormLabel>
              <Input
                fontSize='sm'
                ms='4px'
                borderRadius='15px'
                type='password'
                placeholder='Your password'
                mb='24px'
                size='lg'
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
              <Button
                onClick={handleSignup}
                type='submit'
                bg='orange.500'
                fontSize='10px'
                color='white'
                fontWeight='bold'
                w='100%'
                h='45'
                mb='24px'
                _hover={{
                  bg: "orange.500",
                }}
                _active={{
                  bg: "orange.500",
                }}
              >
                SIGN UP
              </Button>
            </FormControl>
            <Flex
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
              maxW='100%'
              mt='0px'
            >
              <Text color={textColor} fontWeight='medium'>
                Already have an account?
                <Link
                  color={titleColor}
                  as={NavLink}
                  ms='5px'
                  to='/login'
                  fontWeight='bold'
                >
                  Sign In
                </Link>
              </Text>
            </Flex>
          </Flex>
        </Flex>
        {/* <Box
          display={{ base: "none", md: "block" }}
          overflowX='hidden'
          h='100%'
          w='40vw'
          position='absolute'
          right='0px'
        ></Box> */}
      </Flex>
    </Flex>
  );
}

export default SignUp;
