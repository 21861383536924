import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

function ExtractedFieldTable({ data }) {
  const textColor = useColorModeValue("gray.700", "white");

  const columns = Array.from(
    data.reduce((acc, row) => {
      Object.keys(row).forEach((key) => acc.add(key));
      return acc;
    }, new Set())
  );

  return (
    <Table variant="simple">
      <Thead>
        <Tr>
          {columns.map((column) => (
            <Th key={column} color={textColor}>
              <Text fontSize="sm" fontWeight="bold">
                {column}
              </Text>
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {data.map((row, rowIndex) => (
          <Tr key={rowIndex}>
            {columns.map((column) => (
              <Td key={column}>
                <Text fontSize="sm" color={textColor}>
                  {row[column] ?? ""} 
                </Text>
              </Td>
            ))}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}

export default ExtractedFieldTable;
