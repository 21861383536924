import { supabaseClient } from "App";
import { createContext, useEffect, useState } from "react";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

  const [accessToken, setAccessToken] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const getSession = async () => {
    const { data } = await supabaseClient.auth.getSession();
    const token = data?.session?.access_token;
    if (token) {
      setAccessToken(token);
    }
    // if (data?.session?.user.id) {
    //   history.push("/in");
    // }
    setIsLoading(false);
  };

  useEffect(() => {
    !accessToken && getSession();
  }, [accessToken]);

  return (
    <AuthContext.Provider value={{ accessToken, isLoading }}>
      {children}
    </AuthContext.Provider>
  );
};
