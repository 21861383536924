import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import DocumentTableRow from "./TemplateTableRow";
import { getExtractionTemplates } from "api";
import useApiErrorHandler from "hooks/useApiErrorHandler";

const TemplateTable = ({
  title,
  headings,
  data = [],
  setTableData,
  token,
  isLoading,
  setIsLoading,
  currentPage,
  setPageCount,
}) => {
  const textColor = useColorModeValue("gray.700", "white");
  const { handleApiErrors } = useApiErrorHandler();
  const refreshTemplates = async () => {
    setIsLoading(true);

    const originalResponse = await getExtractionTemplates(token, currentPage);
    const data = await handleApiErrors(originalResponse);
    setIsLoading(false);
    setPageCount(Math.ceil(data.total_count / data.per_page));
    setTableData(data.templates);
  };
  // const handleDeleteSelected = () => {
  //   setTableData(data.filter((_, index) => !selectedDocuments.includes(index)));
  //   setSelectedDocuments([]);
  // };
  // const toggleSelectDocument = index => {
  //   setSelectedDocuments(prevSelectedDocuments =>
  //     prevSelectedDocuments.includes(index)
  //       ? prevSelectedDocuments.filter(i => i !== index)
  //       : [...prevSelectedDocuments, index]
  //   );
  // };

  return (
    <Card>
      <CardHeader
        display='flex'
        justifyContent='space-between'
        p='6px 0px 22px 0px'
      >
        <Text fontSize='xl' color={textColor} fontWeight='bold'>
          {title}
        </Text>
      </CardHeader>

      <CardBody overflowX={{ sm: "scroll", xl: "auto" }}>
        <Table
          borderWidth={1}
          borderColor={"#E2E8F0"}
          variant='simple'
          color={textColor}
        >
          <Thead>
            <Tr whiteSpace={"nowrap"} my='.6rem' pl='0px' color='gray.400'>
              {headings?.map((heading, idx) => {
                return (
                  <Th color='gray.400' key={idx} ps={idx === 0 ? "24px" : null}>
                    {heading}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {data?.map((row, index) => {
              return (
                <DocumentTableRow
                  key={`${row.extraction_id}-${row.extraction_name}`}
                  id={row.extraction_id}
                  name={row.extraction_name}
                  documentType={row.document_type}
                  setTableData={setTableData}
                  token={token}
                  index={index}
                  refreshTemplates={refreshTemplates}
                />
              );
            })}
          </Tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default TemplateTable;
