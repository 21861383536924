const BASE_URL = "https://api.docextend.com";
export const getAllProducts = async () => {
  const response = await fetch(`${BASE_URL}/products/all`);

  return response.json();
};

export const getProductById = async id => {
  const response = await fetch(`${BASE_URL}/products/${id}`);
  return response.json();
};

export const importProduct = async payload => {
  const response = await fetch(`${BASE_URL}/products/import`, {
    method: "POST",
    body: payload,
  });
  return response.json();
};
export const createProduct = async (payload, token) => {
  try {
    const response = await fetch(
      "https://dizzy-kora-future-gen-ai-services-5f5fc1c2.koyeb.app/products",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      }
    );
    return response;
  } catch (error) {
    console.error("Error creating product:", error);
  }
};
export const createDescription = async payload => {
  const response = await fetch(`${BASE_URL}/products/description`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  return response.json();
};
export const updateProduct = async (id, product) => {
  const response = await fetch(`${BASE_URL}/products/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(product),
  });
  return response.json();
};

// NEW

// Extractions

// @UploadDocument Page
export const extractDocument = async (file, token, extraction_id = null) => {
  const formData = new FormData();
  // Check if file is valid
  if (file) {
    formData.append("file", file);
  } else if (extraction_id) {
    formData.append("extraction_id", extraction_id);
  } else {
    console.error("No file provided");
    return;
  }
  const response = await fetch(`${BASE_URL}/files/extract`, {
    method: "POST",
    body: formData,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
  // return response.json();
};
export const getSingleDocumentById = async (id, token) => {
  const response = await fetch(`${BASE_URL}/documents/${id}?page=1`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};
export const bulkFileUpload = async (files, extraction_id, token) => {
  const formData = new FormData();

  files.forEach(file => {
    formData.append("files", file);
  });

  formData.append("extraction_id", extraction_id);
  const response = await fetch(`${BASE_URL}/files/upload/bulk`, {
    method: "POST",
    body: formData,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const bulkExtractDocument = async (
  extraction_id,
  bulk_upload_id,
  token
) => {
  const payload = {
    extraction_id,
    bulk_upload_id,
  };
  const response = await fetch(`${BASE_URL}/documents/bulk/extractions`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  });
  return response;
};

export const getBulkExtractionsData = async (
  bulk_upload_id,

  token,
  limit = 1
) => {
  const response = await fetch(
    `${BASE_URL}/documents/bulk/${bulk_upload_id}?page=${limit}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};

export const getBulkExtractionStatus = async (bulk_upload_id, token) => {
  const response = await fetch(
    `${BASE_URL}/documents/bulk/extractions/${bulk_upload_id}/status`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};

export const getDocuments = async (token, page) => {
  let url = `${BASE_URL}/documents`;
  if (page) {
    url += `?page=${page}`;
  }
  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const getDocumentDetails = async (docId, token) => {
  const response = await fetch(`${BASE_URL}/documents/${docId}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const getAllBulkExtractions = async (token, page) => {
  let url = `${BASE_URL}/bulk-extractions`;
  if (page) {
    url += `?page=${page}`;
  }
  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};
export const extractFieldsDocuments = async (docId, fields, token) => {
  const payload = {
    document_id: docId,
    fields,
  };
  const response = await fetch(`${BASE_URL}/documents/fields/extract`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  });
  return response;
};
export const saveExtractedFields = async (docId, payload, token) => {
  const response = await fetch(`${BASE_URL}/documents/${docId}/fields`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  });
  return response;
};
export const saveExtractionTemplate = async (templateName, fields, token) => {
  const payload = {
    template_name: templateName,
    fields,
  };
  const response = await fetch(`${BASE_URL}/extractions/templates`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  });
  return response;
};

// Pagination 10 items per page

// TODO: After Server deployment, the response ?page=1 and etc will give total number of pages or total Templates
export const getExtractionTemplates = async (token, page) => {
  let url = `${BASE_URL}/extractions/templates`;

  if (page) {
    url += `?page=${page}`;
  }
  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};
export const getExtractedTemplate = async (id, token) => {
  const response = await fetch(`${BASE_URL}/extractions/templates/${id}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const updateExtractionTemplate = async (id, payload, token) => {
  const response = await fetch(`${BASE_URL}/extractions/templates/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  });
  return response;
};

export const deleteExtractionTemplate = async (id, token) => {
  const response = await fetch(`${BASE_URL}/extractions/templates/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const createUserOnboarding = async (payload, token) => {
  const response = await fetch(`${BASE_URL}/users/onboard`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  });
  return response;
};

// Exports feature
export const exportBulkToCSV = async (bulk_upload_id, token) => {
  const response = await fetch(
    `${BASE_URL}/documents/csv/bulk/${bulk_upload_id}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};

export const exportBulkToJSON = async (bulk_upload_id, token) => {
  const response = await fetch(
    `${BASE_URL}/documents/json/bulk/${bulk_upload_id}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};

export const exportBulkToExcel = async (bulk_upload_id, token) => {
  const response = await fetch(
    `${BASE_URL}/documents/excel/bulk/${bulk_upload_id}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};

export const getUserInfo = async token => {
  const response = await fetch(`${BASE_URL}/users/me`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};
export const getOrderSuccess = async (sessionId, token) => {
  const response = await fetch(
    `${BASE_URL}/order/success?session_id=${sessionId}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.json();
};

// @TODO: try catch error handle in api calls
