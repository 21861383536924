import { useToast } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

const useApiErrorHandler = () => {
  const toast = useToast({
    position: "top-right",
  });
  const history = useHistory();

  const handleApiErrors = async response => {
    const data = await response.json();
    if (!response.ok) {
      let errorMessage = "Unable to process your request, please try again";

      switch (response.status) {
        case 400:
          errorMessage = data.detail;
          break;
        case 401:
        case 403:
          errorMessage = "Unauthorized access, redirecting to login";
          history.push("/login");
          break;
        case 404:
          errorMessage = data.detail;
          break;
        case 429:
          errorMessage = data.detail;
          break;
        case 409:
          errorMessage = data.detail;
          break;
        default:
          break;
      }

      toast({
        title: "Error",
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    return data;
  };

  return { handleApiErrors };
};

export default useApiErrorHandler;
