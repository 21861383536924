import { getBulkExtractionsData } from "api";
import { AuthContext } from "context/AuthContext";
import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import BulkDocTable from "./components/bulk/BulkDocTable";
import {
  Box,
  Button,
  Flex,
  FormControl,
  HStack,
  MenuButton,
  Spinner,
  Text,
  useToast,
  Menu,
  MenuList,
  Divider,
  MenuItem,
} from "@chakra-ui/react";
import { ChevronDownIcon, ArrowBackIcon } from "@chakra-ui/icons";

import { getBulkExtractionStatus } from "api";
import useApiErrorHandler from "hooks/useApiErrorHandler";
import {
  bulkExtractDocument,
  exportBulkToCSV,
  exportBulkToExcel,
  exportBulkToJSON,
} from "api";

const handleExportError = response => {
  switch (response.status) {
    case 401:
    case 403:
      history.push("/login");
      break;
    case 404:
      toast({
        title: "Error",
        description: response.detail,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      break;
    case 429:
      toast({
        title: "Error",
        description:
          "Your current plan has limitation. Please upgrade your plan",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      break;
    case 409:
      toast({
        title: "Error",
        description: "Conflict error",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      break;
    default:
      toast({
        title: "Error",
        description: "Unable to process your request, please try again",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
  }
};
const BulkExtractProgressPage = () => {
  const { accessToken } = useContext(AuthContext);
  const { handleApiErrors } = useApiErrorHandler();

  const [documents, setDocuments] = useState([]);

  const [extractionTemplate, setExtractionTemplate] = useState(null);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isUploadComplete, setIsUploadComplete] = useState(false);
  const [isExtracting, setIsExtracting] = useState(false);
  const [isProcessed, setIsProcessed] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [documentsStatus, setDocumentsStatus] = useState([
    {
      document_id: "",
      status: "",
    },
    // [{"document_id":"ce5f0940-c7da-4ca4-87eb-2c72241cc977","status":"COMPLETE"}]
  ]);
  const history = useHistory();
  const { id } = useParams();
  const toast = useToast({
    position: "top-right",
  });
  const fetchBulkExtractionData = useCallback(async () => {
    setIsDataLoading(true);
    if (id && id !== "new") {
      try {
        const response = await getBulkExtractionsData(id, accessToken);
        const data = await handleApiErrors(response);

        setIsUploadComplete(data.total_files == data.upload_files_count);
        setDocuments(data.documents || []);
        setExtractionTemplate(data.extraction_template);
        setIsProcessed(!!data.is_extraction_complete);
      } catch (error) {
        history.push("/in/bulk");

        console.error("Error fetching bulk extraction data:", error);
      } finally {
        setIsDataLoading(false);
      }
    }
  }, [id, accessToken]);

  useEffect(() => {
    // need to call first time
    fetchBulkExtractionData();
  }, [fetchBulkExtractionData]);

  // useEffect(() => {
  //   const intervalId = setInterval(
  //     () => !isUploadComplete && fetchBulkExtractionData(),
  //     10000
  //   );

  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, [isUploadComplete, fetchBulkExtractionData]);

  const getExtractionStatus = useCallback(async () => {
    const statusResponse = await getBulkExtractionStatus(id, accessToken);
    const data = await handleApiErrors(statusResponse);

    if (data.some(doc => doc.status === "COMPLETE")) {
      await fetchBulkExtractionData();
      setIsExtracting(false);
    }
    // setDocuments(data => {
    //   return documents.map(async doc => {
    //     const status = await data.find(
    //       status => status.document_id === doc.document_id
    //     );

    //     return !!status ? { ...doc, status: status } : { ...doc };
    //   });
    // });
  }, [accessToken, id]);

  useEffect(() => {
    let intervalId;

    if (isExtracting) {
      intervalId = setInterval(getExtractionStatus, 10000);
    }

    return () => clearInterval(intervalId);
  }, [isExtracting]);

  // useEffect(() => {
  //   if (
  //     documentsStatus &&
  //     documentsStatus.length &&
  //     documents &&
  //     documents.length
  //   ) {
  //     const updatedDocuments = documents.map(doc => {
  //       const status = documentsStatus.find(
  //         status => status.document_id === doc.document_id
  //       );
  //       return !!status ? { ...doc, status: status } : { ...doc };
  //     });
  //     setDocuments(updatedDocuments);
  //   }
  // }, [documentsStatus, documents]);

  const handleStartProcessing = async () => {
    if (!accessToken) {
      console.error("No token found");
      return;
    }
    try {
      setIsExtracting(true);
      const originalResponse = await bulkExtractDocument(
        extractionTemplate.id,
        id,
        accessToken
      );
      const response = await handleApiErrors(originalResponse);
      toast({
        title: "Document extracted",
        description: "The documents extraction has been initiated",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error extracting document:", error);
      setIsExtracting(false);
    }
  };

  const handleExportAsCSV = async () => {
    try {
      setIsExporting(true);
      const response = await exportBulkToCSV(id, accessToken);
      if (!response.ok) {
        handleExportError(response);
      } else {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;

        const templateName = extractionTemplate?.name || "bulk_extraction";
        const now = new Date();

        const timestamp = `${now.getFullYear()}-${
          now.getMonth() + 1
        }-${now.getDate()}_${now.getHours()}-${now.getMinutes()}-${now.getSeconds()}`;
        a.download = `${templateName}_${timestamp}.csv`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    } catch (error) {
      console.error("Error exporting document:", error);
      toast({
        title: "Error exporting document",
        description: "An error occurred while exporting the document.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsExporting(false);
    }
  };

  const handleExportAsJSON = async () => {
    try {
      setIsExporting(true);
      const response = await exportBulkToJSON(id, accessToken);
      if (!response.ok) {
        handleExportError(response);
      } else {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;

        const templateName = extractionTemplate?.name || "bulk_extraction";
        const now = new Date();

        const timestamp = `${now.getFullYear()}-${
          now.getMonth() + 1
        }-${now.getDate()}_${now.getHours()}-${now.getMinutes()}-${now.getSeconds()}`;
        a.download = `${templateName}_${timestamp}.json`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    } catch (error) {
      console.error("Error exporting document:", error);
      toast({
        title: "Error exporting document",
        description: "An error occurred while exporting the document.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsExporting(false);
    }
  };

  const handleExportAsXLS = async () => {
    try {
      setIsExporting(true);
      const response = await exportBulkToExcel(id, accessToken);
      if (!response.ok) {
        handleExportError(response);
      } else {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;

        const templateName = extractionTemplate?.name || "bulk_extraction";
        const now = new Date();

        const timestamp = `${now.getFullYear()}-${
          now.getMonth() + 1
        }-${now.getDate()}_${now.getHours()}-${now.getMinutes()}-${now.getSeconds()}`;
        a.download = `${templateName}_${timestamp}.xls`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    } catch (error) {
      console.error("Error exporting document:", error);
      toast({
        title: "Error exporting document",
        description: "An error occurred while exporting the document.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsExporting(false);
    }
  };
  return (
    <Flex
      flexDirection={{
        base: "column",
        xl: "row",
      }}
      gap={{
        base: 4,
        xl: 0,
      }}
      padding='4'
      position='relative'
    >
      <Box
        width='100%'
        bg='white'
        padding='6'
        ml={{
          base: 0,
          xl: 4,
        }}
      >
        <Box
          display='flex'
          gap={1}
          ml={2}
          alignItems={"center"}
          color='orange.500'
          cursor={"pointer"}
          onClick={() => {
            if (window.history.length > 1) {
              window.history.back();
            } else {
              history.push("/in/bulk/new");
            }
          }}
        >
          <ArrowBackIcon />
          <Text fontSize={"sm"}>Back</Text>
        </Box>
        <Box p={2}>
          <Box display={"flex"} flexDirection={"column"} gap={4}>
            <HStack spacing={4} align='end' position={"relative"}>
              <HStack spacing={4} align='end' width={"100%"}>
                <FormControl
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  {!extractionTemplate ? (
                    <Spinner />
                  ) : (
                    <Text mb={2} fontSize={"md"}>
                      <b>Template Name:</b>{" "}
                      {extractionTemplate?.name || "Not Available"}
                    </Text>
                  )}
                </FormControl>
                <HStack spacing={4} align='end'>
                  <Button
                    bg={"orange.500"}
                    color='white'
                    disabled={isExtracting || isProcessed || isDataLoading}
                    _hover={{ bg: "orange.500" }}
                    _active={{ bg: "orange.500" }}
                    size='md'
                    onClick={handleStartProcessing}
                  >
                    {isProcessed
                      ? "Extracted"
                      : isExtracting
                      ? "Extracting..."
                      : "Start Bulk Extraction"}
                  </Button>
                  <Menu>
                    <MenuButton
                      as={Button}
                      color={"gray.800"}
                      p={"6px 20px"}
                      _hover={{ bg: "gray.100" }}
                      borderWidth={2}
                      rightIcon={<ChevronDownIcon />}
                      variant='outline'
                      disabled={isDataLoading || isExporting}
                    >
                      Export as
                    </MenuButton>
                    <MenuList>
                      <MenuItem onClick={handleExportAsCSV}>CSV</MenuItem>
                      <Divider />
                      <MenuItem onClick={handleExportAsJSON}>JSON</MenuItem>
                      <Divider />
                      <MenuItem onClick={handleExportAsXLS}>XLS</MenuItem>
                    </MenuList>
                  </Menu>
                </HStack>
              </HStack>
            </HStack>
            <BulkDocTable
              title={`Files (${documents.length})`}
              headings={[
                "",
                "Name",
                "Document Type",
                "Industry Domain",
                "Status",
                "Document Link",
              ]}
              token={accessToken}
              rows={documents || []}
            />
          </Box>
        </Box>
      </Box>
    </Flex>
  );
};

export default BulkExtractProgressPage;
