/*eslint-disable*/
import { Flex, Link, Text } from "@chakra-ui/react";

export default function Footer(props) {
  return (
    <Flex
      flexDirection={{
        base: "column",
        xl: "row",
      }}
      alignItems={{
        base: "center",
        xl: "start",
      }}
      justifyContent='space-between'
      // px='30px'
      pb='10px'
      position='absolute'
      bottom={0}
      width='100%'
    >
      <Text
        color='gray.400'
        textAlign={{
          base: "center",
          xl: "start",
        }}
        mx={"auto"}
        mb={{ base: "20px", xl: "0px" }}
      >
        &copy; {2024}, <Text as='span'>{`Made with ❤️ by `}</Text>
        <Link
          color='orange.400'
          href='https://www.futuregen.ai'
          target='_blank'
        >
          Future Gen AI Services, Inc.
        </Link>
      </Text>
    </Flex>
  );
}
