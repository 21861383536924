import React, { createContext, useState } from "react";

export const DocumentContext = createContext();

export const DocumentProvider = ({ children }) => {
  const [extractionId, setExtractionId] = useState(null);
  const [documentId, setDocumentId] = useState(null);
  const [documentType, setDocumentType] = useState(null);
  const [templateInfo, setTemplateInfo] = useState(null);
  const [bulkUploadId, setBulkUploadId] = useState(null);
  const [documents, setDocuments] = useState([]);
  return (
    <DocumentContext.Provider
      value={{
        extractionId,
        setExtractionId,
        documentId,
        setDocumentId,
        templateInfo,
        setTemplateInfo,
        bulkUploadId,
        setBulkUploadId,
        documents,
        setDocuments,
        documentType,
        setDocumentType,
      }}
    >
      {children}
    </DocumentContext.Provider>
  );
};
