import {
  Box,
  Checkbox,
  Flex,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

function BulkTableRow(props) {
  const { selectedDocuments, toggleSelectDocument, index, row = {} } = props;
  const history = useHistory();
  const textColor = useColorModeValue("gray.700", "white");
  return (
    <Tr
      onClick={() => {
        history.push(`/in/bulk/${row.bulkExtractId}`);
      }}
      cursor={"pointer"}
      _hover={{
        bg: "gray.100",
      }}
      transition={"all .3s ease"}
    >
      <Td py={3} pl='0px'>
        <Flex align='center' minWidth='100%' flexWrap='nowrap'>
          <Text
            fontSize='md'
            color={textColor}
            minWidth='100%'
            pl={6}
            whiteSpace={"nowrap"}
          >
            {`${row.templateName}_${
              new Date(row.createdAt)
                .toISOString()
                .replace(/[-:]/g, "-")
                .replace("T", "_")
                .split(".")[0]
            }`}
          </Text>
        </Flex>
      </Td>
      <Td>
        <Text fontSize='md' color={textColor}>
          {row.status?.COMPLETE > 0 && 
           row.status?.FAILED === 0 && 
           row.status?.IN_PROGRESS === 0 && 
           row.status?.UPLOADED === 0 
           ? "Complete" 
           : "In Progress"}
        </Text>
      </Td>
      {/* <Td>
        <Text fontSize='md' color={textColor}>
          {dateModified}
        </Text>
      </Td> */}
    </Tr>
  );
}

export default BulkTableRow;
