import { Route, Switch, Redirect } from "react-router-dom";
import CreateBulkExtractPage from "./CreateBulkExtract";
import BulkExtractProgressPage from "./BulkExtractProgress";

const BulkExtractPage = () => {
  return (
    <>
      <Switch>
        <Route path='/in/bulk/new' exact component={CreateBulkExtractPage} />
        <Route path='/in/bulk/:id' component={BulkExtractProgressPage} />
        <Redirect from='/in/bulk' exact to='/in/bulk/new' />
      </Switch>
    </>
  );
};

export default BulkExtractPage;
