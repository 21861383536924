import { Box, Button, Input, Text, useToast } from "@chakra-ui/react";
import { getExtractedTemplate } from "api";
import { extractDocument } from "api";
import useApiErrorHandler from "hooks/useApiErrorHandler";
import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useHistory } from "react-router-dom";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const UploadDocument = ({
  getRootProps,
  getInputProps,
  inputRef,
  setFile,
  files,
  file,
  token,
  setStep,
  setDocument,
  setExtractionId,
  setExtractionData,
}) => {
  const { handleApiErrors } = useApiErrorHandler();
  const [numPages, setNumPages] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const toast = useToast({
    position: "top-right",
  });
  const history = useHistory();

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    if (numPages > 3) {
      setErrorMessage("Only PDF files with max of 3 pages are allowed.");
    } else {
      setErrorMessage("");
    }
  };
  useEffect(() => {
    if (file?.type !== "application/pdf") {
      setErrorMessage("");
    }
  }, [file]);
  const handleNext = async () => {
    try {
      setIsUploading(true);
      const originalResponse = await extractDocument(file, token);

      const response = await handleApiErrors(originalResponse);

      setIsUploading(false);
      if (response.document_id) {
        setDocument(response);
        setStep(prev => prev + 1);
        if (response?.extraction_id) {
          // ****let's not call this api here****
          // const originalResponse = await getExtractedTemplate(
          //   response.extraction_id,
          //   token
          // );
          //   const data = await handleApiErrors(originalResponse);
          //   setExtractionData(data);
          //   setStep(prev => prev + 1);
        }
      }
    } catch (error) {
      setIsUploading(false);
      console.error("Error extracting document:", error);
    }
  };
  return (
    <>
      <Box
        maxW='1200px'
        border
        borderWidth={"2px"}
        borderStyle={"dashed"}
        borderRadius={"md"}
        p={4}
        cursor={"pointer"}
        {...getRootProps()}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
      >
        {file?.type === "application/pdf" && (
          <Document
            file={file}
            onLoadSuccess={onDocumentLoadSuccess}
            className={"hide"}
            onLoadError={console.error}
          />
        )}
        <Input
          type='file'
          onChange={e => {
            const file = e.target.files[0];
            setFile(file);
          }}
          ref={inputRef}
          border
          bg='red.300'
          {...getInputProps()}
        />
        <Text>Drag 'n' drop some files here, or click to select files</Text>
        <Text mt={4} fontSize='sm' color='gray.500'>
          Only Images and PDF files will be accepted
        </Text>
        {errorMessage && (
          <Text mt={4} fontSize='sm' color='red.500'>
            {errorMessage}
          </Text>
        )}
        {file && <Text>{files}</Text>}
      </Box>
      <Box maxW={"1200px"} mt={4} display={"flex"} justifyContent={"center"}>
        <Button
          bg={"orange.500"}
          _hover={{ bg: "orange.300" }}
          color='#fff'
          onClick={handleNext}
          isDisabled={
            !file || files.length === 0 || errorMessage !== "" || isUploading
          }
        >
          {isUploading ? "Uploading..." : "Next"}
        </Button>
      </Box>
    </>
  );
};

export default UploadDocument;
