import {
  Box,
  Button,
  ChakraProvider,
  Flex,
  Heading,
  Icon,
  Text,
  useColorModeValue,
  useToast,
  Image
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { getOrderSuccess } from "api";
import { supabaseClient } from "App";
import { CheckIcon } from "@chakra-ui/icons";
const OrderSuccess = () => {
  const location = useLocation();
  const history = useHistory();
  const sessionId = new URLSearchParams(location.search).get("session_id");
  const [token, setToken] = useState(null);
  const [order, setOrder] = useState(null);
  const textColor = useColorModeValue("gray.700", "white");
  const toast = useToast();
  useEffect(() => {
    const getUser = async () => {
      const { data, error } = await supabaseClient.auth.getSession();
      if (!data) {
        console.error("Failed to fetch user:", error);
        return;
      }
      setToken(data?.session?.access_token);
    };
    getUser();
  }, []);

  useEffect(() => {
    if (sessionId && token) {
      const getOrder = async () => {
        const response = await getOrderSuccess(sessionId, token);
        if (!response.ok) {
          switch (response.status) {
            case 401:
            case 403:
              history.push("/login");
              break;
            case 404:
              toast({
                title: "Error",
                description: "Resource not found",
                status: "error",
                duration: 5000,
                isClosable: true,
              });
              break;
            case 429:
              toast({
                title: "Error",
                description: "Too many requests, please try again later",
                status: "error",
                duration: 5000,
                isClosable: true,
              });
              break;
            case 409:
              toast({
                title: "Error",
                description: "Conflict error",
                status: "error",
                duration: 5000,
                isClosable: true,
              });
              break;
            default:
              toast({
                title: "Error",
                description: "Unable to process your request, please try again",
                status: "error",
                duration: 5000,
                isClosable: true,
              });
          }
        }
        setOrder(response);
      };
      getOrder();
    }
  }, [sessionId, token]);
  const handleRedirect = () => {
    history.push("/in/overview");
  };

  return (
    <ChakraProvider>
      <Flex direction='column' align='center' justify='center' minH='100vh'>
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={2}
          mt={1}
          mb={8}
        >
          <Image src='/logo-doc-vertical.png' width={100} alt='logo' />
        </Box>
        <Box
          borderWidth={1}
          borderRadius='lg'
          shadow='lg'
          textAlign='center'
          py={10}
          px={6}
        >
          <Icon as={CheckIcon} w={12} h={12} color='green.500' mb={4} />

          <Heading as='h2' size='xl' mb={6} color={textColor}>
            Order Confirmed!
          </Heading>
          <Text fontSize='lg' color={textColor} mb={6}>
            Thank you for your purchase. Your order number is {order?.id}.
          </Text>
          <Button
            rounded={"xl"}
            m={0}
            bg={"orange.400"}
            color={"white"}
            _hover={{
              bg: "orange.300",
              color: "white",
            }}
            _active={{
              bg: "orange.300",
              color: "white",
            }}
            pb={1}
            onClick={handleRedirect}
          >
            Go to Dashboard
          </Button>
        </Box>
      </Flex>
    </ChakraProvider>
  );
};

export default OrderSuccess;
