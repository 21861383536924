import {
  FormControl,
  FormLabel,
  Input,
  Text,
  Tooltip,
  Icon,
  VStack,
  IconButton,
  HStack,
} from "@chakra-ui/react";
import { DownloadIcon, WarningIcon } from "@chakra-ui/icons";
import ExtractedFieldTable from "./ExtractedFieldTable";

function EditableDocumentFields({ extractionFields, onValueChange }) {
  return (
    <VStack spacing={4} align="stretch">
      {extractionFields.map((field, i) => {
        if (field.group_type === "table" && Array.isArray(field.value)) {
          return (
            <>
              <HStack spacing={4} align='end' width={"100%"}>
                <FormLabel mb={0} display="flex" alignItems="center">
                  {field.group}
                </FormLabel>
                <IconButton
                  aria-label="Download Table"
                  icon={<DownloadIcon />}
                  onClick={() => {
                    const header = Object.keys(field.value[0]).join(';') + '\n';
                    const csvData = header + field.value.map(row => 
                      Object.values(row).join(';')
                    ).join('\n');
                    const blob = new Blob([csvData], { type: 'text/csv' });
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = `${field.group}.csv`;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    URL.revokeObjectURL(url);
                  }}
                  variant="outline"
                  size="sm"
                  title="Download as CSV"
                />
              </HStack>
              <ExtractedFieldTable
                key={`${field.id || "table"}-${i}`}
                data={field.value}
              />
            </>
            
          );
        } else {
          return (
            <FormControl key={`${field.id || "id"}-${i}`}>
              <FormLabel mb={0} display="flex" alignItems="center">
                {field.name}
                {field.confidence_score < 0.95 && (
                  <Tooltip label={field.reason}>
                    <Icon as={WarningIcon} color="red.500" ml={2} />
                  </Tooltip>
                )}
              </FormLabel>
              <Text mb={2} color={"gray.400"} fontSize={"sm"}>
                {field.description}
              </Text>
              <Input
                value={field.value || ""}
                onChange={(e) => {
                  e.preventDefault();
                  onValueChange(i, e);
                }}
                borderWidth={field.confidence_score < 0.95 ? 2 : 1}
                borderColor={field.confidence_score < 0.95 ? "red" : ""}
              />
            </FormControl>
          );
        }
      })}
    </VStack>
  );
}

export default EditableDocumentFields;
