import { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Icon,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { FaTrash } from "react-icons/fa";
import DocumentTableRow from "./DocumentTableRow";
import BulkTableRow from "./BulkTableRow";
import { useHistory } from "react-router-dom";
const PagButton = props => {
  const activeStyle = {
    bg: "orange.500",
    _dark: {
      bg: "orange.500",
    },
    color: "white",
  };
  return (
    <Button
      mx={1}
      px={4}
      py={2}
      rounded='md'
      bg='white'
      _dark={{
        bg: "gray.800",
      }}
      color='gray.700'
      onClick={props.onClick}
      disabled={props.disabled}
      opacity={props.disabled && 0.6}
      _hover={!props.disabled && activeStyle}
      cursor={props.disabled && "not-allowed"}
      {...(props.active && activeStyle)}
    >
      {props.children}
    </Button>
  );
};

const DocumentsTable = ({
  title,
  headings,
  data,
  showBulkUpload,
  pageCount,
  currentPage,
  setCurrentPage,
}) => {
  const textColor = useColorModeValue("gray.700", "white");
  const [selectedDocuments, setSelectedDocuments] = useState([]);

  const toggleSelectDocument = index => {
    setSelectedDocuments(prevSelectedDocuments =>
      prevSelectedDocuments.includes(index)
        ? prevSelectedDocuments.filter(i => i !== index)
        : [...prevSelectedDocuments, index]
    );
  };
  const handlePageChange = pageNumber => {
    setCurrentPage(pageNumber);
  };
  return (
    <Card>
      <CardHeader
        display='flex'
        justifyContent='space-between'
        p='6px 0px 22px 0px'
      >
        <Text fontSize='lg' color={textColor} fontWeight='semibold'>
          {title}
        </Text>
      </CardHeader>
      <CardBody overflowX={{ sm: "scroll", xl: "auto" }}>
        <Table
          variant='simple'
          color={textColor}
          borderWidth={1}
          borderColor={"#E2E8F0"}
        >
          <Thead>
            <Tr
              whiteSpace={"nowrap"}
              my='.6rem'
              // pl='0px'
              color='gray.400'
            >
              {headings.map((heading, idx) => {
                return (
                  <Th
                    pl={6}
                    color='gray.400'
                    key={idx}
                    ps={idx === 0 ? "0px" : null}
                  >
                    {heading}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {data.map((row, index) => {
              return !!showBulkUpload ? (
                <BulkTableRow
                  key={`${row.bulkExtractId}-${row.templateName}`}
                  id={row.bulkExtractId}
                  row={row}
                  selectedDocuments={selectedDocuments}
                  toggleSelectDocument={toggleSelectDocument}
                  index={index}
                />
              ) : (
                <DocumentTableRow
                  key={`${row.document_id}-${row.document_name}`}
                  id={row.document_id}
                  row={row}
                  selectedDocuments={selectedDocuments}
                  toggleSelectDocument={toggleSelectDocument}
                  index={index}
                />
              );
            })}
          </Tbody>
        </Table>
      </CardBody>
      {data?.length === 0 && (
        <Flex justifyContent='center' mt={10}>
          <Box>No documents found</Box>
        </Flex>
      )}
      {(data?.length > 0 || currentPage > 1) && (
        <Flex
          _dark={{
            bg: "#3e3e3e",
          }}
          pt={10}
          pb={8}
          w='full'
          alignItems='center'
          justifyContent='center'
        >
          <Flex>
            <PagButton
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <Icon
                as={IoIosArrowBack}
                color='gray.700'
                _dark={{
                  color: "gray.200",
                }}
                boxSize={4}
              />
            </PagButton>
            {[...Array(Math.ceil(pageCount))].map((_, index) => (
              <PagButton
                key={index}
                onClick={() => handlePageChange(index + 1)}
                active={currentPage === index + 1}
              >
                {index + 1}
              </PagButton>
            ))}
            <PagButton
              onClick={() => {
                handlePageChange(currentPage + 1);
              }}
              disabled={currentPage === pageCount}
            >
              <Icon
                as={IoIosArrowForward}
                color='gray.700'
                _dark={{
                  color: "gray.200",
                }}
                boxSize={4}
              />
            </PagButton>
          </Flex>
        </Flex>
      )}
    </Card>
  );
};

export default DocumentsTable;
