import { useToast } from "@chakra-ui/react";
import { getUserInfo } from "api";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

export const useUser = token => {
  const [user, setUser] = useState(null);
  const [isPaidCustomer, setIsPaidCustomer] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // New loading state
  const [isEmailVerified, setIsEmailVerified] = useState(false); // New loading state
  const [error, setError] = useState(null);
  const toast = useToast();
  const history = useHistory()
  const fetchUserInfo = async () => {
    try {
      setIsLoading(true);

      const response = await getUserInfo(token);
      if (!response.ok) {
        setError(response);
        setUser(null);
        setIsPaidCustomer(false);
        setIsEmailVerified(false);
        switch (response.status) {
          case 401:
          case 403:
            history.push("/login");
            break;
          case 404:
            history.push("/in/onboarding");
            break;
          case 429:
            toast({
              title: "Error",
              description: "Too many requests, please try again later",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
            break;
          case 409:
            toast({
              title: "Error",
              description: "Conflict error",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
            break;
          default:
            toast({
              title: "Error",
              description: "Unable to process your request, please try again",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
        }
      } else {
        const loggedinUser = await response.json();
        if (loggedinUser?.user_id) {
          setUser(loggedinUser);
          setIsPaidCustomer(loggedinUser.has_active_subscription);
          setIsEmailVerified(loggedinUser.isEmailVerified)
          if (!loggedinUser.has_active_subscription) {
            history.push("/in/pricing");
          }
        }
      }
    } catch (error) {
      console.error("Error fetching user info", error);
      setError(error);
      setUser(null);
      setIsPaidCustomer(false);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    token && fetchUserInfo();
  }, [token]);

  return { user, isPaidCustomer, isLoading, isEmailVerified, error, setError };
};
