import { Box, Button, Input, Link, Text, useToast } from "@chakra-ui/react";
import { getExtractionTemplates } from "api";
import { getExtractedTemplate } from "api";
import { useEffect, useState } from "react";
import { Document, pdfjs } from "react-pdf";
import { useHistory } from "react-router-dom";
import Select from "react-select";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
import { bulkFileUpload } from "api";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import useApiErrorHandler from "hooks/useApiErrorHandler";

const UploadBulk = ({
  getRootProps,
  getInputProps,
  inputRef,
  setFile,
  files,
  file,
  token,
  setStep,
  setDocumentId,
  setIndustryDomain,
  setDocumentType,
  extractionId,
  setExtractionId,
  setExtractionData,
  selectedFiles,
  setDocuments,
  setBulkUploadId = () => {},
}) => {
  const { handleApiErrors } = useApiErrorHandler();

  const [numPages, setNumPages] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [templateOptions, setTemplateOptions] = useState([
    // { value: "", label: "" },
  ]);
  const [isTemplateLoading, setIsTemplateLoading] = useState(false);
  const toast = useToast({
    position: "top-right",
  });
  const history = useHistory();

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    if (numPages > 3) {
      setErrorMessage("Only PDF files with max of 3 page are allowed.");
      //  setFile(null); // Clear the file if it has more than one page
    } else {
      setErrorMessage("");
    }
  };
  useEffect(() => {
    if (file?.type !== "application/pdf") {
      setErrorMessage("");
    }
  }, [file]);
  const handleNext = async () => {
    setDocuments([]);
    try {
      if (!extractionId) {
        toast({
          title: "Select a template",
          description: "Please select a template for the document",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }
      setIsUploading(true);
      let response;

      const bulkResponse = await bulkFileUpload(
        selectedFiles,
        extractionId,
        token
      );

      response = await handleApiErrors(bulkResponse);
      if (bulkResponse.status === 200 || bulkResponse.status === 201) {
        setBulkUploadId(response.bulk_upload_id);
        history.push(`/in/bulk/${response.bulk_upload_id}`);
        setStep(prev => prev + 1);

        toast({
          title: "Document uploaded successfully",
          description: response?.message,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
      setIsUploading(false);

      if (response.document_id) {
        setDocumentId(response.document_id);
        setIndustryDomain(response.industry_domain);
        setDocumentType(response.document_type);
        if (extractionId) {
          const originalResponse = await getExtractedTemplate(
            extractionId,
            token
          );
          const data = await handleApiErrors(originalResponse);

          setExtractionData(data);
        }

        setStep(prev => prev + 1);
      }
    } catch (error) {
      setIsUploading(false);
      console.error("Error extracting document:", error);
    }
  };

  useEffect(() => {
    if (token) {
      handleTemplateChange();
    }
  }, [token]);

  const handleTemplateChange = async selectedOption => {
    setIsTemplateLoading(true);
    setExtractionId(selectedOption?.value);

    try {
      const templates = await getExtractionTemplates(token);
      // const data = await templates.json();
      setIsTemplateLoading(false);

      const data = await handleApiErrors(templates);
      setTemplateOptions(
        data?.templates?.map(
          template =>
            ({
              value: template.extraction_id,
              label: template.extraction_name,
            } || [])
        )
      );
    } catch (error) {
      console.error("Error fetching templates:", error);
      setIsTemplateLoading(false);
      setTemplateOptions([]);
    } finally {
      setIsTemplateLoading(false);
    }
  };
  return (
    <>
      {
        <Box
          maxW='1200px'
          my={4}
          display='flex'
          flexDirection='column'
          justifyContent='center'
          gap={2}
          width='50%'
        >
          <Text>Select a template for the document</Text>
          <Box display={"flex"} alignItems={"center"} gap={4}>
            <Select
              styles={{
                // control: base => ({
                //   ...base,
                //   "&:hover": {
                //     backgroundColor: "red",
                //   },
                // }),
                container: base => ({
                  ...base,
                  width: "100%",
                  outline: "none",
                  border: "none",
                }),
                option: (provided, state) => ({
                  ...provided,
                  color: "black",
                  // color: state.isSelected ? "white" : "black",
                  backgroundColor: state.isSelected
                    ? "#EDF2F7"
                    : state.isFocused
                    ? "#EDF2F7"
                    : "white",
                  "&:hover": {
                    backgroundColor: "#EDF2F7",
                    cursor: "pointer",
                  },
                }),
              }}
              options={templateOptions}
              placeholder='Select a template'
              isClearable
              isSearchable
              isLoading={isTemplateLoading && templateOptions.length === 0}
              onChange={handleTemplateChange}
            />

            <Link
              variant='unstyled'
              href={extractionId ? `/in/templates/${extractionId}` : "#"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              borderWidth={2}
              borderColor={"gray.200"}
              px={4}
              py={2}
              fontSize={"sm"}
              borderRadius={"md"}
              disabled={!extractionId}
              isExternal={extractionId}
            >
              <ExternalLinkIcon mx='2px' />
              Preview
            </Link>
          </Box>
        </Box>
      }
      <Box
        maxW='1200px'
        border
        borderWidth={"2px"}
        borderStyle={"dashed"}
        borderRadius={"md"}
        p={4}
        cursor={"pointer"}
        {...getRootProps()}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
      >
        {file?.type === "application/pdf" && (
          <Document
            file={file}
            onLoadSuccess={onDocumentLoadSuccess}
            className={"hide"}
            onLoadError={console.error}
          />
        )}
        <Input
          type='file'
          onChange={e => {
            const file = e.target.files[0];

            setFile(file);
          }}
          ref={inputRef}
          border
          bg='red.300'
          {...getInputProps()}
        />
        <Text>Drag 'n' drop some files here, or click to select files</Text>
        <Text mt={4} fontSize='sm' color='gray.500'>
          Only Images and PDF files will be accepted
        </Text>
        {errorMessage && (
          <Text mt={4} fontSize='sm' color='red.500'>
            {errorMessage}
          </Text>
        )}
        {file && <Text>{files}</Text>}
      </Box>

      <Box maxW={"1200px"} mt={4} display={"flex"} justifyContent={"center"}>
        <Button
          bg={"orange.500"}
          _hover={{ bg: "orange.300" }}
          color='#fff'
          onClick={handleNext}
          isDisabled={
            !file ||
            (Array.isArray(files) && files.length === 0) ||
            errorMessage !== "" ||
            isUploading
          }
        >
          {isUploading ? "Uploading..." : "Next"}
        </Button>
      </Box>
    </>
  );
};

export default UploadBulk;
