import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Link,
} from "@chakra-ui/react";
import { groupedCountries } from "data";
import React, { useEffect } from "react";
import { Select } from "chakra-react-select";

const RequiredIcon = () => <sup style={{ color: "red" }}>*</sup>;

const OnboardingForm = ({
  formData,
  companySizeOption,
  handleChange,
  handleSubmit,
  setFormData,
  user,
  consentDetails,
  setConsentDetails,
}) => {
  // const extractCompanyWesite = email => {
  //   const domain = email?.split("@")[1];
  //   return domain;
  // };

  const { email } = user || {};
  const { full_name } = user?.user_metadata || {};
  // const website = extractCompanyWesite(email);

  useEffect(() => {
    if (email) {
      setFormData(prevFormData => ({ ...prevFormData, email }));
    }
  }, [email]);

  useEffect(() => {
    if (full_name) {
      const [firstName, lastName] = full_name.split(" ");
      setFormData(prevFormData => ({ ...prevFormData, firstName, lastName }));
    }
  }, [full_name]);
  return (
    <FormControl my={4} maxW='screen-md' h='full'>
      <Box
        display='grid'
        gridTemplateColumns={{ base: "1fr", sm: "1fr 1fr" }}
        gapX={14}
        w='fit-content'
      >
        {Object.keys(formData)
          .filter(
            key =>
              typeof formData[key] !== "boolean" &&
              key !== "country" &&
              key !== "companySize"
          )
          .map((key, index) => (
            <Box
              key={index}
              display='flex'
              flexDirection='column'
              gap={1}
              mb={4}
              mr={6}
            >
              <FormLabel htmlFor={key}>
                {key !== "companyDetails"
                  ? key
                      .replace(/([a-z])([A-Z])/g, "$1 $2")
                      .replace(/^./, str => str.toUpperCase())
                  : key}
                <RequiredIcon />
              </FormLabel>
              <Input
                type={
                  key.toLowerCase() === "email"
                    ? "email"
                    : key.toLowerCase() === "companywebsite"
                    ? "url"
                    : "text"
                }
                id={key}
                name={key}
                w='210px'
                value={formData[key]}
                isDisabled={key === "email"}
                onChange={e => handleChange(e)}
              />
            </Box>
          ))}
      </Box>

      <Box mb={4} width={"260px"} display='flex' flexDirection='column' gap={1}>
        <FormLabel textTransform='capitalize'>
          {`Company Size`} <RequiredIcon />
        </FormLabel>

        <Select
          placeholder={companySizeOption[0].name}
          onChange={e =>
            setFormData({
              ...formData,
              companySize: e.value,
            })
          }
          options={companySizeOption[0].options}
          borderColor={"orange.500"}
          borderRadius='xl'
          height='2.5rem'
          fontSize='sm'
        />
      </Box>
      <Box display='flex' flexDirection='column' gap={1} w='260px' mb={4}>
        <FormLabel>
          Country or Region
          <RequiredIcon />
        </FormLabel>
        <Select
          placeholder='Choose a country'
          options={groupedCountries}
          isSearchable={true}
          onChange={e => {
            setFormData({ ...formData, country: e.value });
          }}
          styles={{
            control: styles => ({
              ...styles,
              background: "red",
            }),
          }}
        />
      </Box>

      <Box display='flex' gap={8} w='fit-content'>
        <Box
          display='flex'
          flexDirection='row-reverse'
          gap={2}
          alignItems='center'
          mb={4}
        >
          <FormLabel pb={0} mb={0} htmlFor={"terms"}>
            I would like DocExtend to share my information with carefully
            selected partners, view our{" "}
            <Link
              color='orange.500'
              href='https://www.docextend.com/privacy-policy'
              target='_blank'
              textDecoration='underline'
            >
              privacy policy
            </Link>{" "}
            for more details
          </FormLabel>
          <Checkbox
            id={"terms"}
            name={"terms"}
            isChecked={consentDetails.terms}
            onChange={e =>
              setConsentDetails({
                ...consentDetails,
                terms: e.target.checked,
              })
            }
          />
        </Box>
      </Box>

      <Button
        fontWeight='medium'
        bg='orange.500'
        color='white'
        _hover={{ bg: "orange.500" }}
        _active={{ bg: "orange.500" }}
        mt={0}
        onClick={handleSubmit}
      >
        Next
      </Button>
    </FormControl>
  );
};

export default OnboardingForm;
