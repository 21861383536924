import {
  DocumentIcon,
  StatsIcon
} from "components/Icons/Icons";
var dashRoutes = [
  {
    path: "/overview",
    name: "Overview",
    icon: <StatsIcon color='inherit' />,
    layout: "/in",
  },
  {
    path: "/templates",
    name: "Templates",
    icon: <DocumentIcon color='inherit' />,
    layout: "/in",
  }
];

export default dashRoutes;
