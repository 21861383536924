import { CheckIcon } from "@chakra-ui/icons";
import { Box, Divider, Flex, Icon, Text } from "@chakra-ui/react";

const ProgressBar = ({ currentStep, steps }) => {
  return (
    <Box mb={4}>
      <Flex
        as='ol'
        align='center'
        w='full'
        textAlign='center'
        fontSize={{ base: "sm", sm: "base" }}
        fontWeight='medium'
        color='gray.500'
      >
        {steps.map((step, index) => (
          <Flex
            key={step.id}
            align='center'
            w={{ md: "full" }}
            color={currentStep >= step.id ? "orange.500" : "gray.500"}
          >
            <Flex align='center' mx={2}>
              <Icon
                as={CheckIcon}
                w={{ base: 3.5, sm: 4 }}
                h={{ base: 3.5, sm: 4 }}
                me={2.5}
              />
              <Text>
                <Text
                  as='span'
                  display={{ base: "none", sm: "inline-flex" }}
                  ms={2}
                  whiteSpace={"nowrap"}
                >
                  {step.label}
                </Text>
              </Text>
            </Flex>
            {index < steps.length - 1 && (
              <Divider
                display={{ base: "none", sm: "inline-block" }}
                mx={{ base: 2, xl: 4 }}
                borderColor='gray.200'
              />
            )}
          </Flex>
        ))}
      </Flex>
    </Box>
  );
};

export default ProgressBar;
