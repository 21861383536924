import { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { FaTrash } from "react-icons/fa";
import BulkDocTableRow from "./BulkDocTableRow";
import { DocumentContext } from "context/DocumentContext";

const BulkDocTable = ({
  title,
  headings,
  rows = [],

  token,
}) => {
  const textColor = useColorModeValue("gray.700", "white");
  const [selectedDocuments, setSelectedDocuments] = useState([]);

  const handleDeleteSelected = () => {
    setTableData(data.filter((_, index) => !selectedDocuments.includes(index)));
    setSelectedDocuments([]);
  };

  const toggleSelectDocument = index => {
    setSelectedDocuments(prevSelectedDocuments =>
      prevSelectedDocuments.includes(index)
        ? prevSelectedDocuments.filter(i => i !== index)
        : [...prevSelectedDocuments, index]
    );
  };

  return (
    <Card>
      <CardHeader
        display='flex'
        justifyContent='space-between'
        p='6px 0px 22px 0px'
      >
        <Text fontSize='xl' color={textColor} fontWeight='bold'>
          {title}
        </Text>
        <Box display={"flex"} alignItems={"center"} gap={4}>
          <Tooltip
            placement='auto'
            label={`Delete selected documents (${selectedDocuments.length})`}
          >
            <Button
              display={"flex"}
              alignContent={"center"}
              disabled={selectedDocuments.length === 0}
              alignItems={"center"}
              variant='outline'
              // isDisabled={selectedProducts.length === 0}
              // onClick={handleDeleteSelected}
              colorScheme='red'
            >
              Delete
            </Button>
          </Tooltip>
        </Box>
      </CardHeader>
      <CardBody overflowX={{ sm: "scroll", xl: "auto" }}>
        <Table
          borderWidth={1}
          borderColor={"#E2E8F0"}
          variant='simple'
          color={textColor}
        >
          <Thead>
            <Tr whiteSpace={"nowrap"} my='.8rem' pl='0px' color='gray.400'>
              {headings.map((heading, idx) => {
                return (
                  <Th color='gray.400' key={idx} ps={idx === 0 ? "0px" : null}>
                    {heading}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {rows?.map((row, index) => {
              return (
                <BulkDocTableRow
                  key={`row-${index}-${row.name}`}
                  id={index}
                  row={row}
                  setTableData={() => {}}
                  selectedDocuments={selectedDocuments}
                  toggleSelectDocument={toggleSelectDocument}
                  index={index}
                  token={token}
                  documents={[]}
                />
              );
            })}
          </Tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default BulkDocTable;
