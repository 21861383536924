import { useContext, useEffect, useState } from "react";

import { supabaseClient } from "App";
import {
  Box,
  Flex,
  FormControl,
  Image,
  Text,
  useToast,
} from "@chakra-ui/react";
import OnboardingForm from "./components/OnboardingForm";
import { createUserOnboarding } from "api";
import { AuthContext } from "context/AuthContext";
import { useHistory } from "react-router-dom";
import useApiErrorHandler from "hooks/useApiErrorHandler";

const companySizeOption = [
  {
    name: "Select one from below",
    options: [
      { value: "1", label: "1 Person" },
      { value: "2-4", label: "2-4 People" },
      { value: "5-9", label: "5-9 People" },
      { value: "10-24", label: "10-24 People" },
      { value: "25-49", label: "25-49 People" },
      { value: "50-249", label: "50-249 People" },
      { value: "250-999", label: "250-999 People" },
      { value: "1000+", label: "1000+ People" },
    ],
  },
];
const Onboarding = () => {
  const { accessToken } = useContext(AuthContext);
  const { handleApiErrors } = useApiErrorHandler();
  const history = useHistory();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    designation: "",
    country: "",
    companyName: "",
    companySize: "",
    companyWebsite: "",
  });
  const [consentDetails, setConsentDetails] = useState({
    terms: false,
  });
  const [formError, setFormError] = useState({
    firstName: "",
    lastName: "",
    email: "",
    designation: "",
    country: "",
    companyName: "",
    companySize: "",
    companyWebsite: "",
  });
  const [user, setUser] = useState(null);
  // const [token, setToken] = useState(null);
  const toast = useToast({
    position: "top-right",
  });
  useEffect(() => {
    const checkUser = async () => {
      const { data: session } = await supabaseClient.auth.getSession();
      setUser(session?.session.user);
    };
    checkUser();
  }, []);

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (!consentDetails.terms) {
      return toast({
        title: "Please accept terms and conditions",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }

    if (
      !formData.firstName ||
      !formData.lastName ||
      !formData.email ||
      !formData.designation ||
      !formData.country ||
      !formData.companyName ||
      !formData.companySize ||
      !formData.companyWebsite
    ) {
      return toast({
        title: "Please fill all the fields",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }

    if (!formData.email.includes("@")) {
      return toast({
        title: "Please enter a valid email",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    if (formData.companyWebsite && !formData.companyWebsite.includes("http")) {
      return toast({
        title: "Please enter a valid website",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    const payload = {
      first_name: formData.firstName,
      last_name: formData.lastName,
      email: formData.email,
      designation: formData.designation,
      country: formData.country,
      company_name: formData.companyName,
      company_size: formData.companySize,
      company_website: formData.companyWebsite,
      privacy_policy_accepted: true
    };
    try {
      const originalResponse = await createUserOnboarding(payload, accessToken);

      const data = await handleApiErrors(originalResponse);
      if (data.user_id) {
        history.push("/in/pricing");
      }
    } catch (error) {
      console.log("Something went wrong! ", error);
    }
  };

  return (
    <>
      <FormControl pl={10} pt={10} onSubmit={handleSubmit} style={{}}>
        <Flex alignItems={"center"}>
          <Box
            display={"flex"}
            flexDir={"column"}
            // alignItems={"center"}
            pos={"relative"}
            // gap={2}
            // mt={1}
            // mr={2}
          >
            <Image
              src='/logo-doc-vertical.png'
              width={100}
              alt='logo'
              // pos={"absolute"}
              // top={-10}
            />
            {/* <Text fontSize={"lg"} fontWeight='medium' color='#000'>
              DocExtend
            </Text> */}
          </Box>

          <Text fontSize={"x-large"} mt={-1} mx={2}>
            {" "}
            |{" "}
          </Text>

          <Text fontSize={"x-large"} fontWeight={"600"}>
            Tell us about yourself
          </Text>
        </Flex>

        <OnboardingForm
          formData={formData}
          handleChange={handleChange}
          companySizeOption={companySizeOption}
          user={user}
          handleSubmit={handleSubmit}
          consentDetails={consentDetails}
          setFormData={setFormData}
          setConsentDetails={setConsentDetails}
        />
        {Object.keys(formError).map(
          key =>
            formError[key].length > 0 && (
              <Text key={key} className='text-red-500 text-sm'>
                {formError[key]}
              </Text>
            )
        )}
      </FormControl>
    </>
  );
};

export default Onboarding;
