import {
  Button,
  Checkbox,
  Divider,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Td,
  Text,
  Tr,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useToast,
  ModalCloseButton,
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import { FaEllipsisV } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { DocumentContext } from "context/DocumentContext";
import { deleteExtractionTemplate } from "api";
import useApiErrorHandler from "hooks/useApiErrorHandler";

function TemplateTableRow(props) {
  const { id, name, type, domain, token, index, refreshTemplates } = props;
  const history = useHistory();
  const { handleApiErrors } = useApiErrorHandler();
  const textColor = useColorModeValue("gray.700", "white");
  const { setExtractionId } = useContext(DocumentContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toast = useToast({
    position: "top-right",
  });
  const handleView = async e => {
    history.push(`/in/templates/${id}`);
    // const data = await getExtractedTemplateData(id, token);
  };
  const handleReuse = async e => {
    setExtractionId(id);
    history.push(`/in/single-extract/new?template_id=${id}`);
  };
  const handleModelOpen = () => {
    setIsModalOpen(!isModalOpen);
  };
  const handleDeleteTemplate = async (e, id) => {
    e.preventDefault();
    try {
      const response = await deleteExtractionTemplate(id, token);
      await handleApiErrors(response);

      if (response.status === 200) {
        toast({
          title: "Template deleted successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        await refreshTemplates();
      }
    } catch (refreshError) {
      console.error(refreshError);
      toast({
        title: "Template deleted successfully",
        description: "No more templates available.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      // if we dont do this it will keep shwing the 
      
      window.location.reload();
    }
  };

  return (
    <Tr
      cursor={"default"}
      _hover={{
        bg: "gray.100",
      }}
      transition={"all .3s ease"}
    >
      <Td pl='0px' py={3}>
        <Flex
          align='center'
          // minWidth='100%'
          flexWrap='nowrap'
        >
          <Text
            fontSize='md'
            color={textColor}
            // minWidth='100%'
            pl={6}
            whiteSpace={"nowrap"}
          >
            {name}
          </Text>
        </Flex>
      </Td>
      <Td py={3}>
        <Text fontSize='md' color={textColor}>
          {type}
        </Text>
      </Td>
      <Td py={3}>
        <Text fontSize='md' color={textColor}>
          {domain}
        </Text>
      </Td>
      <Td py={3} display={"flex"} gap={4}>
        <Menu>
          <MenuButton>
            <FaEllipsisV />
          </MenuButton>
          <MenuList py={0}>
            <MenuItem py={2} onClick={handleView}>
              View
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleReuse} py={2}>
              Reuse
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleModelOpen} py={2}>
              Delete
            </MenuItem>
          </MenuList>
        </Menu>
      </Td>
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          // isCentered
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Warning: Delete Template</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text>
                Are you sure you want to delete the template <b>{name}</b>?
              </Text>
            </ModalBody>
            <ModalFooter>
              <Button
                bg={"#ff4722"}
                color='white'
                mr={2}
                _hover={{ bg: "#ff4722", color: "#fff" }}
                _active={{ bg: "#ff4722", color: "#fff" }}
                onClick={e => {
                  setIsModalOpen(false);
                  handleDeleteTemplate(e, id);
                }}
              >
                Proceed
              </Button>
              <Button variant='ghost' onClick={() => setIsModalOpen(false)}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Tr>
  );
}

export default TemplateTableRow;
