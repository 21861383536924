import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Text,
  Flex,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Divider,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Spacer,
  Spinner,
  useToast,
  ModalOverlay,
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { useUser } from "hooks/useUser";
import { AuthContext } from "context/AuthContext";
import { createUserOnboarding } from "api";

const UserProfile = () => {
  const { accessToken } = useContext(AuthContext);
  const toast = useToast({
    position: "top-right",
  });
  const { user, isLoading } = useUser(accessToken);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [isManageBillingOpen, setIsManageBillingOpen] = useState(false);

  useEffect(() => {
    if (user) {
      setFirstName(user.first_name || "");
      setLastName(user.last_name || "");
      setEmail(user.email || "");
    }
  }, [user]);

  if (!user?.user_id && isLoading) {
    return <Spinner />;
  }

  const handleSave = async () => {
    if (!firstName || !lastName) {
      toast({
        title: "Error",
        description: "First Name and Last Name are required",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const payload = {
        first_name: firstName,
        last_name: lastName,
      };
      const originalResponse = await createUserOnboarding(payload, accessToken);
      const data = await handleApiErrors(originalResponse);
    } catch (error) {
      console.error(`Something went wrong!`, error);
    }
  };

  const handleCancel = () => {
    // Reset to original name and cancel editing
    setFirstName(user?.first_name);
    setLastName(user?.last_name);
  };
  return (
    <Box
      maxW={"50rem"}
      width='100%'
      // maxW='600px'
      // mx='auto'
      mt='6'
      p='6'
      // border='1px solid #E2E8F0'
      borderRadius='md'
      // boxShadow='md'
    >
      <Text fontSize='2xl' fontWeight='bold' mb='4'>
        Usage Summary
      </Text>

      <Text mb='2'>
        You are currently on the <b>{user?.active_plan}</b>.
        <Text as='span' color='orange.500' cursor='pointer'>
          {" "}
          View all plans and upgrade →
        </Text>
      </Text>

      <Text fontSize='sm' mb='4'>
        {new Date().toLocaleDateString("en-US", {
          month: "long",
          year: "numeric",
        })}{" "}
        01, {new Date().getFullYear()} -{" "}
        {new Date().toLocaleDateString("en-US", {
          month: "long",
          year: "numeric",
        })}{" "}
        30, {new Date().getFullYear()}
      </Text>

      <Divider mb='4' />

      <Table borderWidth={1} variant='striped'>
        <Thead>
          <Tr>
            <Th>Current Usage</Th>
            <Th>Monthly Quota</Th>
            <Th isNumeric>Usage Percentage</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>{user?.used_quota || 0}</Td>
            <Td>{user?.monthly_quota || 0}</Td>
            <Td isNumeric>
              {((user?.used_quota / user?.monthly_quota) * 100).toFixed(2)}%
            </Td>
          </Tr>
        </Tbody>
      </Table>

      <Flex justifyContent='space-between' mt='6'>
        <Button
          onClick={() => {
            setIsManageBillingOpen(true);
          }}
          colorScheme='orange'
          size='sm'
        >
          Manage Billing
        </Button>
        <Button bg='gray.200' size='sm' variant='outline'>
          Download Usage
        </Button>
      </Flex>
      <Divider my='6' />

      <VStack spacing={4} align='start'>
        {/* Name Fields */}
        <FormControl>
          <FormLabel>First Name</FormLabel>
          <Input
            value={firstName}
            onChange={e => setFirstName(e.target.value)}
          />
        </FormControl>

        <FormControl>
          <FormLabel>Last Name</FormLabel>
          <Input value={lastName} onChange={e => setLastName(e.target.value)} />
        </FormControl>

        {/* Email Field (Always Read-Only) */}
        <FormControl>
          <FormLabel>Email Address</FormLabel>
          <Input value={email} disabled />
        </FormControl>

        {/* Action Buttons */}
        <Flex
          flexDir={"row-reverse"}
          // justifyContent={"flex-start"}
          // width='100%'
          gap={3}
          mt={4}
        >
          <>
            <Button
              colorScheme='orange'
              // bg={"orange.400"}
              onClick={handleSave}
            >
              Save
            </Button>
            <Button
              // colorScheme='gray'
              bg={"gray.200"}
              variant='outline'
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </>
        </Flex>
      </VStack>

      <Modal
        isOpen={isManageBillingOpen}
        onClose={() => setIsManageBillingOpen(false)}
        // isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Manage Billing</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* <FormControl>
              <FormLabel>Template Name</FormLabel>
            </FormControl> */}
          </ModalBody>
          <ModalFooter>
            <Button
              variant='ghost'
              onClick={() => setIsManageBillingOpen(false)}
            >
              Cancel
            </Button>
            <Button
              bg={"orange.300"}
              color='white'
              ml={2}
              _hover={{ bg: "orange.300", color: "#fff" }}
              _active={{ bg: "orange.300", color: "#fff" }}
              // onClick={handleSaveTemplate}
            >
              Hello{" "}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default UserProfile;
