import { Spinner, Box, Image, Text } from "@chakra-ui/react";
import { AuthContext } from "context/AuthContext";
import { useUser } from "hooks/useUser";
import { useContext } from "react";
import { Redirect } from "react-router-dom";

function PricingPage() {
  const { accessToken } = useContext(AuthContext);

  const { error, isLoading, user, isPaidCustomer } = useUser(accessToken);

  if (error) {
    return <Redirect to='/login' />;
  }
  if (isLoading) {
    return <Spinner />;
  }
  if (!isPaidCustomer) {
    return (
      <>
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
          gap={1}
          mt={1}
          mb={8}
        >
          <Image src='/logo-doc-vertical.png' width={100} alt='logo' />
        </Box>
        <stripe-pricing-table
          pricing-table-id={process.env.REACT_APP_STRIPE_PRICING_TABLE_ID}
          publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
          client-reference-id={user.user_id}
        ></stripe-pricing-table>
      </>
    );
  }
}

export default PricingPage;
