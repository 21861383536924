import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";
import PrivateRoute from "./PrivateRoute"; // Custom component for protected routes
import SignUp from "views/Auth/SignUp";
import SignIn from "views/Auth/SignIn";
import AdminLayout from "layouts/Admin";
import { useContext } from "react";
import { AuthContext } from "context/AuthContext";
import { Spinner } from "@chakra-ui/react";
import { DocumentProvider } from "context/DocumentContext";

const AppRouter = () => {
  const { isLoading } = useContext(AuthContext);
  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Router>
      <Switch>
        <Route exact path='/' render={() => <Redirect to="/in" />} />
        <Route path='/signup' component={SignUp} />
        <Route path='/login' component={SignIn} />
        <DocumentProvider>
          <PrivateRoute path='/in' component={AdminLayout} />
        </DocumentProvider>
        <Route render={() => <Redirect to="/login" />} />
      </Switch>
    </Router>
  );
};

export default AppRouter;
