import {
  ChevronDownIcon,
  ExternalLinkIcon,
  WarningIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Checkbox,
  Divider,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Td,
  Text,
  Tr,
  useColorModeValue,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Spinner,
  useToast,
  Link,
  Tooltip,
  Icon,
  Image,
  AspectRatio,
} from "@chakra-ui/react";
import { Document, Page, pdfjs } from "react-pdf";
import useApiErrorHandler from "hooks/useApiErrorHandler";

import { getDocumentDetails } from "api";
import { saveExtractedFields } from "api";
import { extractFieldsDocuments } from "api";
import { DocumentContext } from "context/DocumentContext";
import { useContext, useState, useRef, useEffect, useMemo } from "react";
import { FaEllipsisV } from "react-icons/fa";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

function BulkDocTableRow(props) {
  const {
    id,
    row,
    setTableData,
    selectedDocuments,
    toggleSelectDocument,
    index,
    documents,
    token,
  } = props;
  const ctx = useContext(DocumentContext);
  const { handleApiErrors } = useApiErrorHandler();
  const textColor = useColorModeValue("gray.700", "white");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [extractionFields, setExtractionFields] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [fields, setFields] = useState([]);
  const [status, setStatus] = useState("active"); // Add state for status
  const [currentDocument, setCurrentDocument] = useState(null);
  const [documentData, setDocumentData] = useState(null);
  const [isDocumentImgLoading, setIsDocumentImgLoading] = useState(true);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [renderedPageNumber, setRenderedPageNumber] = useState(null);
  const [width, setWidth] = useState(null);

  const pdfWrapper = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (pdfWrapper.current) {
        setWidth(pdfWrapper.current.getBoundingClientRect().width);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    window.addEventListener("load", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const toast = useToast({
    position: "top-right",
  });

  const { setExtractionId, documentId, setDocumentId } = useContext(
    DocumentContext
  );

  function changePage(offset) {
    setRenderedPageNumber(pageNumber);
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    if (pageNumber === 1) return;
    changePage(-1);
  }
  function nextPage() {
    if (pageNumber === numPages) return;
    changePage(1);
  }
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleRowClick = async index => {
    setIsDrawerOpen(true);
    setIsLoading(true);
    setCurrentDocument(documents[index]);
    setDocumentId(row.document_id);
    try {
      const response = await getDocumentDetails(row.document_id, token);
      const data = await handleApiErrors(response);
      setDocumentData({
        ...data,
        fields: data?.fields?.map(field => ({
          ...field,
          initialValue: field.value, // Set initialValue when fetching data
        })),
      });
    } catch (error) {
      setIsDrawerOpen(false);
      console.error("Error fetching document details:", error);
      toast({
        title: "Error fetching document details",
        description: error.message,
        status: "error",
        duration: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleFieldValueChange = (index, e) => {
    const newFields = [...documentData.fields];
    newFields[index].value = e.target.value;
    setDocumentData({ ...documentData, fields: newFields });
  };

  const handleSaveExtractionFields = async () => {
    // setIsSavingExtractionFields(true);
    /*{
    "name": "Shipper Reference",
    "description": "",
    "value": "34567",
    "confidence_score": 0.95,
    "reason": "The shipper's reference is explicitly stated in the document under the relevant section."
}*/
    const fieldsToSave = {
      fields: documentData.fields.map(field => ({
        key: field.key,
        value: field.value,
        ai_generated_value: field.initialValue || field.value, // Use the initial value
        ai_confidence_score: String(field.confidence_score),
        ai_reason: field.reason,
      })),
    };
    try {
      const originalResponse = await saveExtractedFields(
        documentId,
        fieldsToSave,
        token
      );
      const response = await handleApiErrors(originalResponse);

      if (response.status === 200 || response.status === 201) {
        toast({
          title: "Fields Saved",
          description: "The extracted fields have been saved successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error saving fields:", error);
    } finally {
      // setIsSavingExtractionFields(false);
    }
  };

  const statusColor = useMemo(
    () =>
      row?.document_status === "COMPLETE"
        ? "green.500"
        : row?.document_status === "UPLOADED"
        ? "yellow.500"
        : row?.document_status === "FAILED"
        ? "red.500"
        : "gray.500",
    [row?.document_status]
  );
  return (
    <>
      <Tr
        _hover={{
          bg: "gray.100",
        }}
        transition={"all .3s ease"}
        cursor={"pointer"}
        onClick={() => handleRowClick(index)}
      >
        <Td
          onClick={e => {
            e.stopPropagation();
          }}
          width={6}
          p={3}
        >
          <Checkbox
            colorScheme='orange'
            isChecked={selectedDocuments.includes(index)}
            onChange={() => toggleSelectDocument(index)}
          />
        </Td>
        <Td pl={0} py={3}>
          <Flex align='center' minWidth='100%' flexWrap='nowrap'>
            <Text
              fontSize='md'
              color={textColor}
              minWidth='100%'
              pl={6}
              whiteSpace={"nowrap"}
            >
              {row.document_filename}
            </Text>
          </Flex>
        </Td>

        <Td py={3}>{row?.document_type}</Td>
        <Td py={3}>{row?.document_industry_domain}</Td>
        <Td color={statusColor} py={3}>
          {row?.document_status.charAt(0).toUpperCase() +
            row?.document_status.slice(1).toLowerCase()}
        </Td>
        <Td py={3}>
          <Link
            variant='unstyled'
            href={row?.document_url}
            onClick={e => e.stopPropagation()}
            display={"flex"}
            alignItems={"center"}
            // justifyContent={"center"}
            fontSize={"sm"}
            borderRadius={"md"}
            isExternal
          >
            <ExternalLinkIcon mx='2px' />
            View
          </Link>
        </Td>
      </Tr>

      <Drawer
        isOpen={isDrawerOpen}
        placement='right'
        onClose={() => setIsDrawerOpen(false)}
        size='md'
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          {!isLoading && (
            <DrawerHeader>
              Extracted Fields of{" "}
              {currentDocument?.document_filename || row?.document_filename}
            </DrawerHeader>
          )}

          <DrawerBody>
            {isLoading ? (
              <Spinner />
            ) : (
              <VStack spacing={4} align='stretch'>
                {(documentData?.fields, documentData?.fields?.length) && (
                  <Link
                    // width={"fit-content"}
                    display={"block"}
                    href={documentData?.document_url}
                    isExternal
                    // height={"390px"}
                    height={"fit-content"}
                    width={"300px"}
                  >
                    {documentData?.file_name?.split(".").pop() === "pdf" ? (
                      <Box ref={pdfWrapper} w={"100%"} className={"pdfWrapper"}>
                        <iframe
                          src={documentData?.document_url}
                          style={{
                            height: "387px",
                            userSelect: "none",
                          }}
                        />
                      </Box>
                    ) : (
                      <AspectRatio
                        ratio={1}
                        // maxW='1300px'
                        minH={"390px"}
                        minW={"300px"}
                        maxH='400px'
                      >
                        <Image
                          src={documentData?.document_url}
                          alt={documentData?.file_name}
                          objectFit='cover'
                        />
                      </AspectRatio>
                    )}
                  </Link>
                )}
                {documentData?.fields?.map((field, i) => (
                  <FormControl key={`${field.id || "id"}-${i}`}>
                    <FormLabel mb={0} display='flex' alignItems='center'>
                      {field.key}
                      {field.confidence_score < 0.95 && (
                        <Tooltip label={field.reason}>
                          <Icon as={WarningIcon} color='red.500' ml={2} />
                        </Tooltip>
                      )}
                    </FormLabel>
                    <Text mb={2} color={"gray.400"} fontSize={"sm"}>
                      {field.description}
                    </Text>
                    <Input
                      value={field.value}
                      onChange={e => {
                        e.preventDefault();
                        handleFieldValueChange(i, e);
                      }}
                      borderWidth={field.confidence_score < 0.95 ? 2 : 1}
                      borderColor={field.confidence_score < 0.95 ? "red" : ""}
                    />
                  </FormControl>
                ))}
                {(!documentData?.fields ||
                  documentData?.fields?.length === 0) && (
                  <Text color={"gray.400"} fontSize={"sm"}>
                    No fields have been extracted yet.
                  </Text>
                )}
              </VStack>
            )}
          </DrawerBody>

          <DrawerFooter>
            <Button
              variant='outline'
              mr={3}
              onClick={() => {
                setIsDrawerOpen(false);
                setExtractionFields([]);
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={
                !documentData?.fields || documentData?.fields?.length === 0
              }
              // colorScheme='blue'
              bg={"orange.500"}
              color={"white"}
              _hover={{ bg: "orange.500" }}
              _active={{ bg: "orange.500" }}
              onClick={handleSaveExtractionFields}
            >
              Save
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default BulkDocTableRow;
