import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { supabaseClient } from "App";
import {
  Box,
  Flex,
  Heading,
  Image,
  Spinner,
  Text,
} from "@chakra-ui/react";

const VerifyPage = () => {
  const [email, setEmailAddress] = useState("");
  const [isLoading, setIsLoading] = useState(true)
  const history = useHistory();
  useEffect(() => {
    const checkUser = async () => {
      setIsLoading(true)
      const { data } = await supabaseClient.auth.getUser();
      setEmailAddress(data?.user?.email)
      if (
        data.user.email_confirmed_at ||
        data.user.user_metadata?.email_confirmed_at
      ) {
        history.push("/in/onboarding");
      }
      setIsLoading(false)
    };
    checkUser();
  }, []);

  if (isLoading) {
    return <Spinner />
  }
  return (
    <Flex align='center' justify='center' height='100vh'>
      <Box borderWidth='1px' borderRadius='lg' p={5} textAlign='center'>
        <Image
          src='https://static.vecteezy.com/system/resources/previews/004/459/449/non_2x/email-confirmation-color-icon-e-mail-approval-response-hiring-letter-email-with-check-mark-employment-verification-letter-isolated-illustration-vector.jpg'
          alt='logo'
          boxSize='80px'
          mx='auto'
        />
        <Heading as='h1' size='xl' mb={4}>
          Verify your email address
        </Heading>
        <Text color='gray.500' mb={4}>
          We have sent a verification link to your email address ({email || "NA"}
          ). <br />
          Please verify your email to continue.
        </Text>
        {/* <Button colorScheme='orange' onClick={handleVerify} mb={4}>
          Verify your email
        </Button> */}
      </Box>
    </Flex>
  );
};

export default VerifyPage;
