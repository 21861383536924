import React, { useContext, useLayoutEffect } from "react";
// Chakra imports
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Switch,
  Text,
  useColorModeValue,
  UnorderedList,
  ListItem,
  Center,
  useToast,
  Image,
} from "@chakra-ui/react";
import { NavLink, useHistory, Redirect } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";

import { supabaseClient } from "App";
import { AuthContext } from "context/AuthContext";

function SignIn() {
  const toast = useToast({
    position: "top-right",
  }); // Chakra color mode
  const titleColor = useColorModeValue("orange.500");
  const textColor = useColorModeValue("gray.400", "white");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const history = useHistory();

  const handleEmailChange = e => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = e => {
    setPassword(e.target.value);
  };
  const handleSignin = React.useCallback(async () => {
    try {
      if (email && password) {
        const { data, error } = await supabaseClient.auth.signInWithPassword({
          email: email,
          password: password,
        });
        if (data.user) {
          toast({
            title: "Sign in successful",
            description: "You have successfully signed in.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          history.push("/in/onboarding");
        }

        if (error) {
          console.error(error);
          throw error;
        }
      } else {
        console.error("email and password are required to signin");
        toast({
          title: "Input Error",
          description: "Email and password are required to sign in.",
          status: "warning",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Something went wrong",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }, [email, password, toast]);
  const handleGoogleSignin = React.useCallback(async () => {
    try {
      const { error } = await supabaseClient.auth.signInWithOAuth({
        provider: "google",
      });
      if (error) {
        console.error(error);
        throw error;
      }
    } catch (error) {
      toast({
        title: "Google Sign In Error",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }, [toast]);

  return (
    <Flex position='relative' zIndex={10} mb='40px'>
      <Flex
        h={{ sm: "initial", md: "75vh", lg: "85vh" }}
        w='100%'
        maxW='1044px'
        mx='auto'
        justifyContent='space-between'
        mb='30px'
        pt={{ sm: "100px", md: "0px" }}
      >
        <Flex
          alignItems='center'
          justifyContent='start'
          // style={{ userSelect: "none" }}
          pos={"relative"}
          zIndex={10}

          // w={{ base: "100%", md: "50%", lg: "42%" }}
        >
          <Box w='100%' mr={6} display='flex' flexDirection='column' gap='6'>
            <Image
              src='/logo-doc.png'
              alt='logo'
              // width={10}
              width={150}
              height={12}
              objectFit='cover'
            />
            <Heading as='h2' fontSize='2xl' fontWeight='bold'>
              Streamline your document management with intelligent data
              extraction solutions.
            </Heading>
            <Box>
              <Text fontWeight='bold'>
                Automatically capture key information from your documents:
              </Text>
              <UnorderedList spacing={2} ml='6' fontWeight=''>
                <ListItem>
                  Extract invoice details quickly—customize, verify, and export
                  with ease.
                </ListItem>
                <ListItem>
                  Save and manage templates for consistent extraction across all
                  documents.
                </ListItem>
                <ListItem>
                  Extract data from multiple documents and export to
                  spreadsheets for analysis.
                </ListItem>
                <ListItem>
                  Ensure accuracy with confidence scores and real-time alerts
                  for validation.
                </ListItem>
              </UnorderedList>
            </Box>
          </Box>
          <Flex
            direction='column'
            w='100%'
            background='transparent'
            p='48px'
            mt={{ md: "150px", lg: "80px" }}
            textAlign={"center"}
          >
            <Heading color={titleColor} fontSize='32px' mb='10px'>
              Sign In
            </Heading>
            <Center py={8}>
              <Button
                w={"full"}
                maxW={"md"}
                variant={"outline"}
                leftIcon={<FcGoogle />}
                onClick={handleGoogleSignin}
              >
                <Center>
                  <Text>Sign in with Google</Text>
                </Center>
              </Button>
            </Center>
            <Text
              mb='36px'
              ms='4px'
              color={textColor}
              fontWeight='bold'
              fontSize='14px'
            >
              Enter your email and password to sign in
            </Text>

            <FormControl
              onKeyDown={e => {
                if (e.key === "Enter" || e.keyCode === 13 || e.which === 13) {
                  handleSignin();
                }
              }}
            >
              <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                Email
              </FormLabel>
              <Input
                borderRadius='15px'
                mb='24px'
                fontSize='sm'
                type='text'
                placeholder='Your email adress'
                size='lg'
                onChange={handleEmailChange}
              />
              <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                Password
              </FormLabel>
              <Input
                borderRadius='15px'
                mb='36px'
                fontSize='sm'
                type='password'
                placeholder='Your password'
                size='lg'
                onChange={handlePasswordChange}
              />

              <Button
                fontSize='10px'
                type='submit'
                bg='orange.500'
                w='100%'
                h='45'
                mb='20px'
                color='white'
                mt='20px'
                _hover={{
                  bg: "orange.500",
                }}
                _active={{
                  bg: "orange.500",
                }}
                onClick={handleSignin}
              >
                SIGN IN
              </Button>
            </FormControl>
            <Flex
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
              maxW='100%'
              mt='0px'
            >
              <Text color={textColor} fontWeight='medium'>
                Don't have an account?
                <Link
                  zIndex={10}
                  to='/signup'
                  color={titleColor}
                  as={NavLink}
                  ms='5px'
                  fontWeight='bold'
                >
                  Sign Up
                </Link>
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default SignIn;
