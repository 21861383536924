// Chakra imports
import { Spinner, Box, useToast } from "@chakra-ui/react";
import Footer from "components/Footer/Footer.js";

import Sidebar from "components/Sidebar/Sidebar";
import { useState, useEffect, useContext } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import routes from "routes";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
// Custom components
import PanelContainer from "../components/Layout/PanelContainer";
import PanelContent from "../components/Layout/PanelContent";
import { useHistory } from "react-router-dom";
import { AuthContext } from "context/AuthContext";
import ExtractionTemplatesListPage from "views/Dashboard/ExtractionTemplates/List";
import BulkExtractPage from "views/Dashboard/Documents/BulkExtract";
import DocumentsListPage from "views/Dashboard/Documents/List";
import DocumentSingleExtractPage from "views/Dashboard/Documents/SingleExtract";
import DocumentSingleExtractDetailPage from "views/Dashboard/Documents/DocumentSingleExtractDetailPage";
import ExtractionTemplateDetailPage from "views/Dashboard/ExtractionTemplates/Detail";
import PricingPage from "views/Pricing";
import Onboarding from "views/Onboarding";
import { useUser } from "hooks/useUser";
import { useLocation } from "react-router-dom";
import UserProfile from "views/Dashboard/Profile/UserProfile";
import OrderSuccess from "views/OrderSuccess";
import VerifyPage from "views/Auth/Verify";

const pathsToHideSidebar = ["/in/onboarding", "/in/pricing", "/in/verify-email", "/in/order/success", "/in/order/failure"];

export default function AdminLayout(props) {
  const { ...rest } = props;
  // states and functions
  const location = useLocation();
  const currentPath = location.pathname;
  const shouldHideSidebar = pathsToHideSidebar.includes(currentPath);

  // functions for changing the states from components
  const { accessToken } = useContext(AuthContext);
  const { error, isLoading, user, isPaidCustomer, isEmailVerified } = useUser(accessToken);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const history = useHistory();
  const toast = useToast();
  useEffect(() => {
    if (!isLoading && error) {
      if (error.status === 404) {
        history.push("/in/onboarding");
      } else {
        toast({
          title: "Authentication Error",
          description: error.message,
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        history.push("/login");
      }
    } else if (!isPaidCustomer && !isLoading) {
      history.push("/in/pricing");
    } else if (!isEmailVerified && !isLoading) {
      history.push("/in/verify-email")
    }
  }, [error, history]);

  if (isLoading) {
    return <Spinner />;
  }

  // Chakra Color Mode
  return (
    <>
      {!shouldHideSidebar && (
        <Sidebar
          routes={routes}
          logoText={"DocExtend"}
          display='none'
          {...rest}
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
          userData={user}
        />
      )}
      <Box
        ml={isSidebarOpen ? "275px" : "75px"}
        p='4'
        flex='1'
        minH='100vh'
        overflow='auto'
        transition='margin-left 0.3s'
      >
        <PanelContent>
          <PanelContainer>
            <Switch>
              {!isPaidCustomer && (
                <Route path='/in/pricing' component={PricingPage} />
              )}
              {!user?.user_id && (
                <Route path='/in/onboarding' component={Onboarding} />
              )}
               {!isEmailVerified && (
                <Route path='/in/verify-email' component={VerifyPage} />
              )}
              <Route
                path='/in/single-extract/new'
                component={DocumentSingleExtractPage}
              />
              <Route
                path='/in/single-extract/:id'
                component={DocumentSingleExtractDetailPage}
              />
              <Route path='/in/bulk' component={BulkExtractPage} />

              <Route
                path='/in/templates/:id'
                component={ExtractionTemplateDetailPage}
              />
              <Route
                path='/in/templates'
                component={ExtractionTemplatesListPage}
              />
              <Route path='/in/profile' component={UserProfile} />
              <Route from='/in/overview' component={DocumentsListPage} />
              <Route from='/in/order/success' component={OrderSuccess} />
              {/* exact */}
              <Redirect from='/in' to='/in/overview' />
            </Switch>
          </PanelContainer>
        </PanelContent>
      </Box>

      <Footer />
    </>
  );
}
