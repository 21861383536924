import { Box, Flex, Spinner, Stack, Text } from "@chakra-ui/react";
import { useContext, useEffect, useMemo, useState } from "react";
import DocumentsTable from "./components/DocumentsTable";
import { AuthContext } from "context/AuthContext";
import { getDocuments, getAllBulkExtractions } from "api";
import useApiErrorHandler from "hooks/useApiErrorHandler";

const DocumentsListPage = () => {
  const { accessToken } = useContext(AuthContext);
  const { handleApiErrors } = useApiErrorHandler();
  const [documents, setDocuments] = useState([]);
  const [bulkExtractionDocuments, setBulkExtractionDocuments] = useState([]);
  const [documentsPageCount, setDocumentsPageCount] = useState(0);
  const [currentDocumentsPage, setCurrentDocumentsPage] = useState(1);
  const [bulkExtractionPageCount, setBulkExtractionPageCount] = useState(0);
  const [currentBulkExtractionPage, setCurrentBulkExtractionPage] = useState(1);

  const [isLoading, setIsLoading] = useState(true);
  const [isBulkLoading, setIsBulkLoading] = useState(true);

  const bulkExtractList = useMemo(() => {
    if (
      bulkExtractionDocuments?.bulk_extractions &&
      bulkExtractionDocuments.bulk_extractions.length
    ) {
      return bulkExtractionDocuments.bulk_extractions.map(extraction => ({
        bulkExtractId: extraction.bulk_upload_id,
        templateName: extraction.extraction_template?.extraction_name || "",
        createdBy: extraction.created_by,
        createdAt: extraction.created_at,
        isExtractionComplete: extraction.is_extraction_complete,
      }));
    }
    return [];
  }, [bulkExtractionDocuments?.bulk_extractions]);
  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        setIsLoading(true);
        const singleResponse = await getDocuments(
          accessToken,
          currentDocumentsPage
        );

        // if (!singleResponse.ok) {
        //   throw Error("documents fetching error");
        // }

        const singleData = await handleApiErrors(singleResponse);
        setDocuments(singleData);
        setDocumentsPageCount(
          Math.ceil(singleData.total_count / singleData.per_page)
        );
      } catch (error) {
        console.error("Error fetching documents:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchDocuments();
  }, [accessToken, currentDocumentsPage]);

  useEffect(() => {
    const fetchBulkDocuments = async () => {
      try {
        setIsBulkLoading(true);
        const bulkResponse = await getAllBulkExtractions(
          accessToken,
          currentBulkExtractionPage
        );

        // if (!bulkResponse.ok) {
        //   throw Error("bulk documents fetching error");
        // }

        const bulkData = await handleApiErrors(bulkResponse);
        setBulkExtractionDocuments(bulkData);
        setBulkExtractionPageCount(
          Math.ceil(bulkData.total_count / bulkData.per_page)
        );
      } catch (error) {
        console.error("Error fetching bulk documents:", error);
      } finally {
        setIsBulkLoading(false);
      }
    };
    fetchBulkDocuments();
  }, [accessToken, currentBulkExtractionPage]);

  return (
    <Stack>
      <Box p={4}>
        <Text fontSize='xl' ml={3} fontWeight='bold'>
          Overview
        </Text>
        <Flex direction='column' ml={3} pt={{ base: "80px", md: "30px" }}>
          {isLoading && <Spinner ml={6} mt={4} />}
          {!isLoading && (
            <DocumentsTable
              title={"Single Extracted Documents"}
              headings={["Name", "Template", "Status"]}
              data={documents?.documents || []}
              pageCount={documentsPageCount}
              currentPage={currentDocumentsPage}
              setCurrentPage={setCurrentDocumentsPage}
            />
          )}
        </Flex>
      </Box>
      <Box p={4}>
        {/*  pt={{ base: "80px", md: "30px" }} */}
        <Flex direction='column' ml={3}>
          {isBulkLoading && <Spinner ml={6} mt={4} />}
          {!isBulkLoading && (
            <DocumentsTable
              title={"Bulk Extracted Documents"}
              showBulkUpload={true}
              headings={["Name", "Status"]}
              data={bulkExtractList}
              pageCount={bulkExtractionPageCount}
              currentPage={currentBulkExtractionPage}
              setCurrentPage={setCurrentBulkExtractionPage}
            />
          )}
        </Flex>
      </Box>
    </Stack>
  );
};

export default DocumentsListPage;
