import { Box, Button, Flex, Icon, Spinner, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import DocumentsTable from "./components/TemplateTable";
import { getExtractionTemplates } from "api";
import { supabaseClient } from "App";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useHistory } from "react-router-dom";
import useApiErrorHandler from "hooks/useApiErrorHandler";

const templateColumns = ["Name", "Type", "Domain", "Actions"];

const PagButton = props => {
  const activeStyle = {
    bg: "orange.500",
    _dark: {
      bg: "orange.500",
    },
    color: "white",
  };
  return (
    <Button
      mx={1}
      px={4}
      py={2}
      rounded='md'
      bg='white'
      _dark={{
        bg: "gray.800",
      }}
      color='gray.700'
      onClick={props.onClick}
      disabled={props.disabled}
      opacity={props.disabled && 0.6}
      _hover={!props.disabled && activeStyle}
      cursor={props.disabled && "not-allowed"}
      {...(props.active && activeStyle)}
    >
      {props.children}
    </Button>
  );
};

const ExtractionTemplatesListPage = () => {
  const toast = useToast();
  const { handleApiErrors } = useApiErrorHandler();
  const [tableData, setTableData] = useState([]);
  const [token, setToken] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const history = useHistory();
  useEffect(() => {
    const getUser = async () => {
      const { data, error } = await supabaseClient.auth.getSession();
      const { session } = data;
      if (!data) {
        console.error("Failed to fetch user:", error);
        return;
      }

      setToken(session?.access_token);
    };
    getUser();
  }, [token]);
  useEffect(() => {
    const fetchTemplates = async () => {
      setIsLoading(true);
      try {
        const originalResponse = await getExtractionTemplates(
          token,
          currentPage
        );

        const data = await handleApiErrors(originalResponse);
        setIsLoading(false);
        setPageCount(Math.ceil(data.total_count / data.per_page));
        setTableData(data.templates);

        // setPageCount(Math.ceil(originalResponse.totalTemplates / itemsPerPage));
      } catch (error) {
        console.error("Error fetching templates", error);
        // toast({
        //   title: "Error fetching templates",
        //   description: error.message,
        //   status: "error",
        //   duration: 9000,
        //   position: "top-right",
        //   isClosable: true,
        // });
        setIsLoading(false);
      }
    };
    if (token) fetchTemplates();
  }, [token, currentPage]);
  const handlePageChange = pageNumber => {
    setCurrentPage(pageNumber);
  };
  return (
    <Box p={4}>
      {/* gap={4} mt={20}  display={"flex"} */}
      {/* <Text fontSize='xl' mb={8} ml={3} fontWeight='bold'>
        Templates
      </Text> */}
      <Flex direction='column' ml={3}>
        {isLoading && <Spinner ml={6} mt={4} />}
        {!isLoading && (
          <>
            <DocumentsTable
              title={"Templates"}
              headings={templateColumns}
              token={token}
              data={tableData}
              setTableData={setTableData}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              currentPage={currentPage}
              setPageCount={setPageCount}
            />
            {!tableData?.length && (
              <Flex justifyContent='center' mt={10}>
                <Box>No templates found</Box>
              </Flex>
            )}
            {(tableData?.length > 0 || currentPage > 1) && (
              <Flex
                _dark={{
                  bg: "#3e3e3e",
                }}
                p={50}
                w='full'
                alignItems='center'
                justifyContent='center'
              >
                <Flex>
                  <PagButton
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    <Icon
                      as={IoIosArrowBack}
                      color='gray.700'
                      _dark={{
                        color: "gray.200",
                      }}
                      boxSize={4}
                    />
                  </PagButton>
                  {[...Array(Math.ceil(pageCount))].map((_, index) => (
                    <PagButton
                      key={index}
                      onClick={() => handlePageChange(index + 1)}
                      active={currentPage === index + 1}
                    >
                      {index + 1}
                    </PagButton>
                  ))}
                  <PagButton
                    onClick={() => {
                      handlePageChange(currentPage + 1);
                    }}
                    disabled={currentPage === pageCount}
                  >
                    <Icon
                      as={IoIosArrowForward}
                      color='gray.700'
                      _dark={{
                        color: "gray.200",
                      }}
                      boxSize={4}
                    />
                  </PagButton>
                </Flex>
              </Flex>
            )}
          </>
        )}
      </Flex>
    </Box>
  );
};

export default ExtractionTemplatesListPage;
